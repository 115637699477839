import { useState, useEffect } from "react";
import {
  makeStyles,
  Card,
  createStyles,
  Grid,
  withStyles,
  TextField,
  CardContent,
  FormControlLabel,
  Switch,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import React from "react";
import CustomCardTitle from "../../../../../../interfaces/common/card/CustomCardTitle";
import { IProgramCatalogGeneralDetails } from "../../../../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/IProgramCatalogGeneralDetails";
import { useFormContext, FieldError } from "react-hook-form";
import StatusAutoComplete from "../../../../../../components/AutoComplete/StatusAutoComplete";
import InputField from "../../../../../../components/_Layout/Inputs/InputField";
import { InputType } from "../../../../../../constants/uiConstants/inputConstants";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ProgramVersionDistanceEducationStatusAutoComplete from "../../../../../../components/AutoComplete/ProgramVersionDistanceEducationStatusAutoComplete";
import { IProgramCatalogFinancialDetails } from "../../../../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/IProgramCatalogFinancialDetails";
import { DropDownListItem } from "../../../../../../interfaces/DropDownListItem";
import AccreditingAgencyAutoComplete from "../../../../../../components/AutoComplete/AccreditingAgencyAutoComplete";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      textDecoration: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
      height: "100%",
    },
    cardWrapper: {
      marginBottom: theme.spacing(2),
    },
    datePickerField: {
      width: "100%",
      background: "white",
      borderRadius: theme.spacing(0.5),
      "& .MuiInputBase-root": {
        "& input": {
          width: "100%",
        },
      },
    },
    fullWidthField: {
      width: "100%",
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "80%",
    },
    switch: {
      marginLeft: "0px",
      paddingTop: theme.spacing(2),
    },
    preLoader: {
      textAlign: "center",
    },
  })
);

const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {
      "& .MuiFormLabel-root": {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "100%",
      },
    },
  })
)(TextField);

type ProgramCatalogGeneralDetailsProps = {
  financial: IProgramCatalogFinancialDetails;
  model: IProgramCatalogGeneralDetails;
  setModel: any;
  loading?: boolean;
};
const ProgramCatalogGeneralDetails = (
  props: ProgramCatalogGeneralDetailsProps
) => {
  const classes = useStyles({});
  const { model, setModel } = props;
  const data = useFormContext();

  const yesNo: Array<DropDownListItem> = [
    { text: "Yes", id: "yes" },
    { text: "No", id: "no" }
  ];

  const yesNoAutoComplete = {
    options: yesNo,
    getOptionLabel: (option: DropDownListItem) => option.text,
  };

  const getSelectedItem = (items: Array<DropDownListItem>, value: string) => {
    const item = items.find((opt) => {
      if (opt.id == value) return opt;
    });
    return item || ({} as DropDownListItem);
  };

  const MaximumTimeFrameProgramCredits = React.useMemo(() => {
    if (!!model.creditsInProgram && model.creditsInProgram > 0 && !!model.maxTimeToCompleteProgramPct && model.maxTimeToCompleteProgramPct > 0) {
      return Math.ceil((Number(model.creditsInProgram) * Number(model.maxTimeToCompleteProgramPct)) / 100);
    }else{
      return undefined;
    }
  },[model.creditsInProgram, model.maxTimeToCompleteProgramPct]);

  const handleFieldChange = (fieldId: string, value: any) => {
    let updatedModel = model;
    (updatedModel as any)[fieldId] = value;

    if(fieldId == "creditsInProgram" && (updatedModel.faProgramCredits == null || updatedModel.faProgramCredits == 0))
    {
      (updatedModel as any)["faProgramCredits"] = value;
    }
    setModel({ ...updatedModel });
  };
  useEffect(() => {
    data.triggerValidation(["effectiveStartDate", "effectiveEndDate"]);
  }, [model]);
  useEffect(() => {
    data.setValue("effectiveStartDate", model.effectiveStartDate);
    data.setValue("effectiveEndDate", model.effectiveEndDate);
    data.triggerValidation(["effectiveStartDate", "effectiveEndDate"]);
  }, [model.effectiveEndDate, model.effectiveStartDate]);

  return (
    <Card square={true} className={classes.cardWrapper}>
      <CustomCardTitle title={"General"}></CustomCardTitle>
      <CardContent>
        {(props.loading && (
          <div className={classes.preLoader}>
            <CircularProgress />
          </div>
        )) || (
          <React.Fragment>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} sm={12} md={3}>
                <ThemeTextField
                  id="name"
                  label="Program Version*"
                  name="name"
                  className={classes.fullWidthField}
                  value={model.name}
                  onChange={(e: any) => {
                    handleFieldChange("name", e.target.value);
                  }}
                  error={!!data.errors.name}
                  inputRef={data.register({ required: true })}
                  helperText={
                    data.errors.name
                      ? "Program Version is required."
                      : undefined
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <StatusAutoComplete
                  id="statusId"
                  name="statusId"
                  label="Status *"
                  valueFilter={
                    model.statusId
                      ? {
                          key: "value",
                          values: [model.statusId],
                        }
                      : undefined
                  }
                  filterHandle={(v: any) => {
                    handleFieldChange("statusId", v ? v.value : null);
                  }}
                  error={!!data.errors.statusId}
                  inputRef={data.register({ required: true })}
                  helperText={
                    data.errors.statusId ? "Status is required." : undefined
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    clearable
                    format={"MM/dd/yyyy"}
                    placeholder="mm/dd/yyyy"
                    id="effectiveStartDate"
                    name="effectiveStartDate"
                    label="Effective Start Date"
                    TextFieldComponent={ThemeTextField}
                    className={classes.datePickerField}
                    value={
                      model.effectiveStartDate ? model.effectiveStartDate : null
                    }
                    onChange={(value: any) => {
                      handleFieldChange(
                        "effectiveStartDate",
                        value ? value : null
                      );
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    autoOk={true}
                    // required
                    error={!!data.errors["effectiveStartDate"]}
                    innerRef={(ref: any) => {
                      data.register("effectiveStartDate", {
                        validate: {
                          beforeEndDate: (value) =>
                            !value ||
                            new Date(value) <
                              new Date(data.getValues()["effectiveEndDate"]) ||
                            "Start Date must be before end date",
                        },
                      });
                      data.setValue(
                        "effectiveStartDate",
                        model.effectiveStartDate
                      );
                    }}
                    helperText={
                      data.errors["effectiveStartDate"]
                        ? (data.errors["effectiveStartDate"] as FieldError)
                            .message
                        : undefined
                    }
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    clearable
                    format={"MM/dd/yyyy"}
                    placeholder="mm/dd/yyyy"
                    id="effectiveEndDate"
                    name="effectiveEndDate"
                    label="Effective End Date"
                    TextFieldComponent={ThemeTextField}
                    className={classes.datePickerField}
                    value={
                      model.effectiveEndDate ? model.effectiveEndDate : null
                    }
                    onChange={(value: any) => {
                      handleFieldChange(
                        "effectiveEndDate",
                        value ? value : null
                      );
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    autoOk={true}
                    // required
                    error={!!data.errors["effectiveEndDate"]}
                    innerRef={(ref: any) => {
                      data.register("effectiveEndDate", {
                        validate: {
                          beforeEndDate: (value) =>
                            !value ||
                            new Date(value) >
                              new Date(
                                data.getValues()["effectiveStartDate"]
                              ) ||
                            "End Date must be after Start date",
                        },
                      });
                      data.setValue("effectiveEndDate", model.effectiveEndDate);
                    }}
                    helperText={
                      data.errors["effectiveEndDate"]
                        ? (data.errors["effectiveEndDate"] as FieldError)
                            .message
                        : undefined
                    }
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} sm={12} md={3}>
                <InputField
                  type={InputType.NUMBER}
                  key="creditsInProgram"
                  id="creditsInProgram"
                  label="Program Credits *"
                  name="creditsInProgram"
                  defaultValue={model.creditsInProgram}
                  decimal
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                    handleFieldChange("creditsInProgram", e.target.value);
                  }}
                  error={!!data.errors.creditsInProgram}
                  inputRef={data.register({
                    validate: {
                      requiredWhenClockHour: (value) =>
                        (value !== "" && value !== undefined) ||
                        "Hours Required",
                    },
                  })}
                  helperText={
                    data.errors.creditsInProgram
                      ? (data.errors.creditsInProgram as FieldError).message
                      : undefined
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <InputField
                  type={InputType.NUMBER}
                  key="faProgramCredits"
                  id="faProgramCredits"
                  label="FA Program Credits *"
                  name="faProgramCredits"
                  defaultValue={model.faProgramCredits}
                  decimal
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                    handleFieldChange("faProgramCredits", e.target.value);
                  }}
                  error={!!data.errors.faProgramCredits}
                  inputRef={data.register({
                    required: true,
                    validate: {
                      max: () =>
                        !model.faProgramCredits ||
                        model.faProgramCredits <= 1000
                          ? true
                          : "FA program credits should not exceed 1000.",
                      min: () =>
                        !model.faProgramCredits ||
                        model.faProgramCredits > -1
                          ? true
                          : "FA program credits should be grater than -1.",
                    },
                  })}
                  helperText={
                    data.errors.faProgramCredits
                      ? (data.errors.faProgramCredits as FieldError).message
                      : undefined
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
              <InputField
                  type={InputType.NUMBER}
                  key="weeksInProgram"
                  id="weeksInProgram"
                  label="Weeks in program *"
                  name="weeksInProgram"
                  defaultValue={model.weeksInProgram}
                  decimal
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                    handleFieldChange("weeksInProgram", e.target.value);
                  }}
                  error={!!data.errors.weeksInProgram}
                  inputRef={data.register({
                    required: true,
                  })}
                  helperText={
                    data.errors.weeksInProgram
                      ? (data.errors.weeksInProgram as FieldError).message
                      : undefined
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <InputField
                  type={InputType.NUMBER}
                  key="creditsInAcademicYear"
                  id="creditsInAcademicYear"
                  label="Credits in Academic Year *"
                  name="creditsInAcademicYear"
                  defaultValue={model.creditsInAcademicYear}
                  decimal
                  decimalPlaces={2}
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                    handleFieldChange("creditsInAcademicYear", e.target.value);
                  }}
                  error={!!data.errors.creditsInAcademicYear}
                  inputRef={data.register({
                    required:true,
                  })}
                  helperText={
                    data.errors.creditsInAcademicYear
                      ? (data.errors.creditsInAcademicYear as FieldError).message
                      : undefined
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <InputField
                  type={InputType.NUMBER}
                  key="weeksInAcademicYear"
                  id="weeksInAcademicYear"
                  label="Weeks in academic year *"
                  name="weeksInAcademicYear"
                  decimal
                  defaultValue={model.weeksInAcademicYear}
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                    handleFieldChange("weeksInAcademicYear", e.target.value);
                  }}
                  error={!!data.errors.weeksInAcademicYear}
                  inputRef={data.register({
                    required: true,
                  })}
                  helperText={
                    data.errors.weeksInAcademicYear
                      ? (data.errors.weeksInAcademicYear as FieldError).message
                      : undefined
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <InputField
                  type={InputType.NUMBER}
                  key="maxTimeToCompleteProgramPct"
                  id="maxTimeToCompleteProgramPct"
                  label="Maximum time frame to complete program (%) *"
                  name="maxTimeToCompleteProgramPct"
                  decimal
                  defaultValue={model.maxTimeToCompleteProgramPct}
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                    handleFieldChange(
                      "maxTimeToCompleteProgramPct",
                      e.target.value
                    );
                  }}
                  error={!!data.errors.maxTimeToCompleteProgramPct}
                  inputRef={data.register({
                    required: true,
                    validate: {
                      max: () =>
                        !model.maxTimeToCompleteProgramPct ||
                        model.maxTimeToCompleteProgramPct <= 150
                          ? true
                          : "MTF to complete program in % can't be more than 150%.",
                      min: () =>
                        !model.maxTimeToCompleteProgramPct ||
                        model.maxTimeToCompleteProgramPct >= 1
                          ? true
                          : "MTF to complete program in % can't be less than 1%",
                    },
                  })}
                  helperText={
                    data.errors.maxTimeToCompleteProgramPct
                      ? (data.errors.maxTimeToCompleteProgramPct as FieldError)
                          .message
                      : undefined
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12} md={3}>
                <InputField
                  type={InputType.NUMBER}
                  key="maximumTimeFrameProgramCredits"
                  id="maximumTimeFrameProgramCredits"
                  label="Maximum time frame to complete program (Credits)"
                  name="maximumTimeFrameProgramCredits"
                  decimal
                  value={MaximumTimeFrameProgramCredits ?? ''}
                  onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                    handleFieldChange(
                      "maximumTimeFrameProgramCredits",
                      e.target.value
                    );
                  }}
                  disabled
                />
              </Grid>

              <Grid item xs={12} sm={12} md={3}>
                <InputField
                  type={InputType.NUMBER}
                  key="minimumAttendanceAllowed"
                  id="minimumAttendanceAllowed"
                  label="Minimum attendance % allowed *"
                  name="minimumAttendanceAllowed"
                  defaultValue={model.minimumAttendanceAllowed}
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                    handleFieldChange(
                      "minimumAttendanceAllowed",
                      e.target.value
                    );
                  }}
                  error={!!data.errors.minimumAttendanceAllowed}
                  inputRef={data.register({
                    required: true,
                    validate: {
                      max: () =>
                        !model.minimumAttendanceAllowed ||
                        model.minimumAttendanceAllowed <= 100
                          ? true
                          : "Allowed Attendance % should not exceed 100.",
                      min: () =>
                        !model.minimumAttendanceAllowed ||
                        model.minimumAttendanceAllowed >= 0
                          ? true
                          : "Allowed Attendance % should be grater than 0.",
                    },
                  })}
                  helperText={
                    data.errors.minimumAttendanceAllowed
                      ? (data.errors.minimumAttendanceAllowed as FieldError)
                          .message
                      : undefined
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12} md={3}>
                <InputField
                  type={InputType.NUMBER}
                  key="paymentPeriodsPerAcademicYear"
                  id="paymentPeriodsPerAcademicYear"
                  label="Pay Periods per Academic Year *"
                  name="paymentPeriodsPerAcademicYear"
                  defaultValue={model.paymentPeriodsPerAcademicYear}
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                    handleFieldChange(
                      "paymentPeriodsPerAcademicYear",
                      e.target.value
                    );
                  }}
                  error={!!data.errors.paymentPeriodsPerAcademicYear}
                  inputRef={data.register({
                    required: true,
                    validate: () => {
                      if (
                        props.financial.isTitleIV &&
                        model.paymentPeriodsPerAcademicYear &&
                        model.paymentPeriodsPerAcademicYear > 2
                      ) {
                        return "Pay Periods should not exceed 2.";
                      }
                      return true;
                    },
                  })}
                  helperText={
                    data.errors.paymentPeriodsPerAcademicYear
                      ? (
                          data.errors
                            .paymentPeriodsPerAcademicYear as FieldError
                        ).message
                      : undefined
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <InputField
                  type={InputType.NUMBER}
                  key="fullTimeDefinition"
                  id="fullTimeDefinition"
                  label="Full time definition *"
                  name="fullTimeDefinition"
                  defaultValue={model.fullTimeDefinition}
                  decimal
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                    handleFieldChange("fullTimeDefinition", e.target.value);
                  }}
                  error={!!data.errors.fullTimeDefinition}
                  inputRef={data.register({
                    required: true,
                  })}
                  helperText={
                    data.errors.fullTimeDefinition
                      ? (data.errors.fullTimeDefinition as FieldError).message
                      : undefined
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <InputField
                  type={InputType.NUMBER}
                  key="addDropPeriod"
                  id="addDropPeriod"
                  label="Add/Drop Period"
                  name="addDropPeriod"
                  defaultValue={model.addDropPeriod}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">days</InputAdornment>
                    ),
                  }}
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                    handleFieldChange("addDropPeriod", e.target.value);
                  }}
                  error={!!data.errors.addDropPeriod}
                  inputRef={data.register({
                    validate: () => {
                      if (
                        model.addDropPeriod &&
                        (model.addDropPeriod < 0 || model.addDropPeriod > 99)
                      ) {
                        return "Add/Drop Period must be in range(00-99).";
                      }
                      return true;
                    },
                  })}
                  helperText={
                    data.errors.addDropPeriod
                      ? (data.errors.addDropPeriod as FieldError).message
                      : undefined
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <ProgramVersionDistanceEducationStatusAutoComplete
                  id="distanceEducationStatusId"
                  name="distanceEducationStatusId"
                  label="Distance Education Status *"
                  valueFilter={
                    model.distanceEducationStatusId
                      ? {
                          key: "value",
                          values: [model.distanceEducationStatusId],
                        }
                      : undefined
                  }
                  filterHandle={(v: any) => {
                    handleFieldChange(
                      "distanceEducationStatusId",
                      v ? v.value : null
                    );
                  }}
                  error={!!data.errors.distanceEducationStatusId}
                  inputRef={data.register({
                    required: true,
                  })}
                  helperText={
                    data.errors.distanceEducationStatusId
                      ? (data.errors.distanceEducationStatusId as FieldError)
                          .message
                      : undefined
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
              <AccreditingAgencyAutoComplete
                  id="accreditingAgencyId"
                  name="accreditingAgencyId"
                  label="Accrediting Agency"
                  valueFilter={
                    model.accreditingAgencyId
                      ? {
                        key: "value",
                        values: [model.accreditingAgencyId],
                      }
                      : undefined
                  }
                  filterHandle={(v: any) => {
                    handleFieldChange(
                      "accreditingAgencyId",
                      v ? v.value : null
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
              <Autocomplete
                  {...yesNoAutoComplete}
                  autoComplete
                  includeInputInList
                  onChange={(e: any, value: any) => {
                    handleFieldChange(
                      "meetsLicensureRequirements",
                      value ? value.id : undefined
                    );
                  }}
                  value={getSelectedItem(yesNo, model.meetsLicensureRequirements ?? '')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Meets Licensure requirements"
                      name="meetsLicensureRequirements"
                      error={!!data.errors.meetsLicensureRequirements}
                      inputRef={data.register({ required: false })}
                      helperText={
                        data.errors.meetsLicensureRequirements
                          ? "Meets Licensure requirements is required."
                          : undefined
                      }
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={3}>
                <FormControlLabel
                  id="isContinuingEducation"
                  className={classes.switch}
                  labelPlacement="start"
                  label="Continuing education"
                  control={
                    <Switch
                      checked={model.isContinuingEducation ? true : false}
                      name="isContinuingEducation"
                      onChange={(e: any) => {
                        handleFieldChange(
                          "isContinuingEducation",
                          e ? e.target.checked : false
                        );
                      }}
                      color="primary"
                    />
                  }
                />
              </Grid>

            </Grid>
          </React.Fragment>
        )}
      </CardContent>
    </Card>
  );
};

export default ProgramCatalogGeneralDetails;
