export const UndefinedChargeProgramApiAutoComplete = {
    config: {
        acId: "UndefinedChargePrograms",
        cacheTime: 5,
        route: "/AcademicRecords/ChargeDefinition/GetUndefinedChargePrograms",
        requestType: "GET",
        body: null,
        hasUrlParams: true,
        useCache: false,
        storeInCache: false,
        isCampusSpecific: true,
        isUserSpecific: false,
        loadOnRender: true,
        defaultSelectedFirstItem: false,
        optionsMappedFx: (data: Array<any>) => {
            return data;
        },
    },
};
