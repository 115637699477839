import * as React from "react";
import {
  makeStyles,
  createStyles,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Button,
  Box,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddFutureCharges from "./AddFutureCharges";
import { FormContext, useForm, FieldError } from "react-hook-form";
import IPEDSAutoComplete from "../../../components/AutoComplete/IPEDSAutoComplete";
import EnrollmentStatusAutoComplete from "../../../components/AutoComplete/EnrollmentStatusAutoComplete";
import { useSelector, useDispatch } from "react-redux";
import InputField from "../../../components/_Layout/Inputs/InputField";
import { InputType } from "../../../constants/uiConstants/inputConstants";
import { EmptyGuid } from "../../../utils/constants";
import { StudentProfile } from "../../../interfaces/student/profile/StudentProfile";
import * as statusCodeApi from "../../../api/setup/catalog/statusCodesApi";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      fontWeight: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(0),
    },
    headerText: {
      float: "left",
      padding: theme.spacing(1.5),
    },
    dialogCloseButton: {
      float: "right",
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
  })
);

type AddNotesPopUpProps = {
  open: boolean;
  handleClose: any;
  isLoading: boolean;
  setLoading: any;
  setSnackBar: any;
  setConfirmationDialog: any;
};

type IDropReason = {
  campusId: string;
  id: string;
  description: string;
  code: string;
  ipedsDropReasonId: string;
  sysStatusId: string;
};

const DropReasonPopup = (props: Readonly<AddNotesPopUpProps>) => {
  const classes = useStyles({});
  const [loaderState, setLoaderState] = React.useState<boolean>(false);

  const data = useForm<any>({ mode: "onBlur" });
  const { handleSubmit } = data;

  const selectedStudent: StudentProfile = useSelector(
    (state: any) => state.student
  ) as StudentProfile;

  var defaultModel = {
    campusId: "",
    id: EmptyGuid,
    description: "",
    code: "",
    ipedsDropReasonId: "",
    sysStatusId: "",
  };

  const [model, setModel] = React.useState<IDropReason>(defaultModel);

  React.useEffect(() => {
    if (selectedStudent?.selectedEnrollment?.campusId) {
      setModel({
        ...model,
        campusId: selectedStudent?.selectedEnrollment?.campusId,
      });
    }
  }, [selectedStudent]);

  const handleFieldOnChange = (fieldId: string, value: any) => {
    let updatedModel = model;
    (updatedModel as any)[fieldId] = value;
    setModel({ ...updatedModel });
  };

  const onSubmit = () => {
    data.triggerValidation().then((validation: any) => {
      if (validation) {
        setLoaderState(true);
        statusCodeApi.AddDropReason(model).then(
          (response: any) => {
            if (response.resultStatus === 0) {
              setLoaderState(false);
              props.setSnackBar((props: any) => {
                return {
                  variant: "success",
                  showSnackBar: true,
                  messageInfo: "Drop reason added successfully.",
                };
              });
              props.handleClose();
            } else {
              setLoaderState(false);
              props.setSnackBar((props: any) => {
                return {
                  variant: "error",
                  showSnackBar: true,
                  messageInfo: "Error posting charges.",
                };
              });
            }
          },
          (exception: any) => {
            setLoaderState(false);
            props.setSnackBar((snackBarProps: any) => {
              return {
                variant: "error",
                showSnackBar: true,
                messageInfo: exception,
              };
            });
          }
        );
      }
    });
  };

  const beforeClose = () => {
    props.handleClose(false);
  };

  return (
    <React.Fragment>
      <Dialog
        PaperProps={{ square: true }}
        fullWidth={true}
        maxWidth="sm"
        open={props.open ?? false}
        onClose={beforeClose}
        aria-labelledby="notes-add-dialog-title"
      >
        <DialogTitle
          className={classes.cardTitle}
          disableTypography
          id="notes-add-dialog-title"
        >
          <Typography className={classes.cardTitle}>
            <div className={classes.headerText}>Add Drop Reason</div>
            <IconButton
              aria-label="close"
              onClick={beforeClose}
              className={classes.dialogCloseButton}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <FormContext {...data}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container direction="row" spacing={1}>
                <Grid item md={12} sm={12} xs={12}>
                  <Grid container direction="row" spacing={1}>
                    <Grid item md={12} sm={12} xs={12}>
                      <InputField
                        type={InputType.TEXT}
                        label="Description *"
                        name="description"
                        key="description"
                        defaultValue={model?.description}
                        onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                          handleFieldOnChange("description", v.target.value);
                        }}
                        error={!!data.errors.description}
                        inputRef={data.register({
                          required: "Description is required.",
                        })}
                        helperText={
                          data.errors.description
                            ? (data.errors.description as FieldError).message
                            : undefined
                        }
                      />
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <InputField
                        type={InputType.TEXT}
                        label="Code *"
                        name="code"
                        key="code"
                        defaultValue={model?.code}
                        onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                          handleFieldOnChange("code", v.target.value);
                        }}
                        error={!!data.errors.code}
                        inputRef={data.register({
                          required: "Field is required.",
                        })}
                        helperText={
                          data.errors.code
                            ? (data.errors.code as FieldError).message
                            : undefined
                        }
                      />
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <IPEDSAutoComplete
                        id="ipedsDropReasonId"
                        name="ipedsDropReasonId"
                        label="IPEDS"
                        description="Drop Reason"
                        valueFilter={
                          model.ipedsDropReasonId
                            ? {
                                key: "value",
                                values: [model.ipedsDropReasonId],
                              }
                            : undefined
                        }
                        filterHandle={(v: any) => {
                          handleFieldOnChange(
                            "ipedsDropReasonId",
                            v ? v.value : null
                          );
                        }}
                      />
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <EnrollmentStatusAutoComplete
                        filterHandle={(v: any) => {
                          handleFieldOnChange(
                            "sysStatusId",
                            12
                            //v ? v.value : null
                          );
                        }}
                        systemStatusId={12}
                        params={{
                          fullWidth: true,
                        }}
                        id="sysStatusId"
                        name="sysStatusId"
                        label="Status *"
                        error={!!data.errors.sysStatusId}
                        inputRef={data.register({ required: true })}
                        helperText={
                          data.errors.sysStatusId
                            ? "Status code is required."
                            : undefined
                        }
                        valueFilter={
                          model?.sysStatusId
                            ? {
                                key: "value",
                                values: [model?.sysStatusId],
                              }
                            : undefined
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid spacing={1}>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  mt={2}
                  mb={2}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onSubmit()}
                    style={{ marginRight: "8px" }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => props.handleClose()}
                    style={{ marginRight: "8px" }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>
            </form>
          </FormContext>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default DropReasonPopup;
