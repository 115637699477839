import React, { useState, useEffect } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  Switch,
  RadioGroup,
  Radio,
  Typography,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { FormContext, useForm, FieldError } from "react-hook-form";
import {
  IStudentTermination,
  IStudentEnrollments,
  IR2T4Step1Input,
  IR2T4Input,
  IProgramVersionDetail,
  IScheduleHoursToComplete,
  ITerminationResult,
  IPeriodTypes,
  IOverriddenData,
  IPostWithdrawalData,
  IStudentFinancialData,
  mapR2T4Result,
  mapR2T4Result1,
  IR2T4Result,
} from "../../../interfaces/student/termination/IStudentTermination";
import * as terminationApi from "../../../api/student/termination/studentTerminationApi";
import TerminationEnrollmentStatusAutoComplete from "../../../components/AutoComplete/TerminationEnrollmentStatusAutoComplete";
import DropReasonAutoComplete from "../../../components/AutoComplete/DropReasonAutoComplete";
import { StudentProfile } from "../../../interfaces/student/profile/StudentProfile";
import { EmptyGuid } from "../../../utils/constants";
import MuiAccordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import CustomCardTitle from "../../../interfaces/common/card/CustomCardTitle";
import { withStyles } from "@material-ui/core/styles";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import InfoIcon from "@material-ui/icons/Info";
import IconButton from "@material-ui/core/IconButton";
import CurrencyInput from "../../../components/_Layout/Inputs/CurrencyInput";
import InputField from "../../../components/_Layout/Inputs/InputField";
import { InputType } from "../../../constants/uiConstants/inputConstants";

import ResultsTab1 from "./ResultsTab1";
import ResultsTab2 from "./ResultsTab2";
import ResultsTab3 from "./ResultsTab3";
import ResultsTab4 from "./ResultsTab4";
import ResultsTab5 from "./ResultsTab5";
import ResultsTab6 from "./ResultsTab6";
import ResultsTab7 from "./ResultsTab7";
import ResultsTab8 from "./ResultsTab8";
import ResultsTab9 from "./ResultsTab9";
import ResultsTab10 from "./ResultsTab10";
import ResultsTab11 from "./ResultsTab11";

const Accordion = withStyles({
  root: {
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      minHeight: "100%",
      paddingTop: theme.spacing(3),
      overflowX: "hidden",
      overflowY: "auto",
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
    startDateField: {
      width: "100%",
    },
  })
);

interface ITerminationProps {
  student: StudentProfile;
  enrollment: IStudentEnrollments | undefined;
  periodTypes: IPeriodTypes[];
  enrollmentId: string;
  isResultExists: boolean;
  setIsResultExists: any;
  terminationId: string;
  campusId: string;
  setConfirmationDialog: (params: any) => void;
  setSnackBar: (params: any) => void;
  setTab: (params: any) => void;
  deleteTermination: (params: any) => void;
  showLoading: any;
  hideLoading: any;
}

const R2T4InputTab = (props: ITerminationProps) => {
  const { campusId, enrollmentId, terminationId, student, enrollment, periodTypes } = props;
  const classes = useStyles({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isResultExists, setIsResultExists] = React.useState<boolean | null>(null);
  const [studentTermination, setStudentTermination] = React.useState<IStudentTermination>();
  const [titleIVGrants, setTitleIVGrants] = React.useState<IR2T4Step1Input>();
  const [r2t4Result, setR2t4Result] =
    React.useState<IR2T4Result>()
  const [terminationResult, setTerminationResult] =
    React.useState<ITerminationResult>();
  const [studentFinancialData, setStudentFinancialData] =
    React.useState<IStudentFinancialData>();
  const [r2T4Input, setR2T4Input] = React.useState<IR2T4Input>();
  const [programVersionDetail, setProgramVersionDetail] =
    React.useState<IProgramVersionDetail>();
  const [scheduleHoursToComplete, setScheduleHoursToComplete] =
    React.useState<IScheduleHoursToComplete>();
  const [userAllowedToOverrideR2T4Input, setUserAllowedToOverrideR2T4Input] =
    React.useState<boolean>(false);
  const [override, setOverride] = React.useState<boolean>(false);
  const [isClockHour, setIsClockHour] = React.useState<boolean>(false);
  const data = useForm<any>({ mode: "onBlur" });

  useEffect(() => {
    GetUserAllowedToOverrideR2T4Input();
    GetIsClockHour(enrollmentId);
    getTermination(enrollmentId);
  }, [enrollmentId]);

  useEffect(() => {
     GetIsResultsExists(terminationId);     
   }, [terminationId]);

   const getTermination = (enrollmentId: any) => {
    terminationApi.GetStudentTermination(enrollmentId).then(
      (response: any) => {
        if (response) {
          setStudentTermination(response);
        }
      },
      (exception: any) => {}
    );
  };

  const GetIsResultsExists = async (terminationId1: string) => {
    terminationApi.GetIsResultsExists(terminationId1).then(
      (response: any) => { 
        if (response) { 
          setIsResultExists(response);
        }
        else
        {
          setIsResultExists(false);
        }
      },
      (exception: any) => {}
    );
  }

  useEffect(() => {
    if(isResultExists == true)
    {
      GetTerminationResult(terminationId);
    }
    else if (isResultExists == false)
    {
      GetR2T4Result();
    } 
  }, [isResultExists]);

  const GetR2T4Result = () => {
    terminationApi
    .GetCalculateR2T4Input(terminationId, true)
    .then(
      (response: any) => {
        if (response) {
          setR2T4Input({ ...response });          
          terminationApi.GetR2T4Result(response).then(
            (response1: any) => {
              setStudentFinancialData(response1);
              var result = mapR2T4Result(response1);
              setTerminationResult({...result, terminationId: terminationId, r2T4ResultsId: EmptyGuid, withdrawalDate: props.enrollment?.lastDateAttended ?? null });

              var result1 = mapR2T4Result1(response1);
              setR2t4Result({...result1, terminationId: terminationId, r2T4ResultsId: EmptyGuid});
            },
            (exception: any) => {}
          );
        }
      },
      (exception: any) => {}
    );
  }

  const GetTerminationResult = (terminationId: string) => {
    props.showLoading();
    terminationApi.GetTerminationResult(terminationId).then(
      (response: any) => {
        if (response) {
          //setTerminationResult(response);          

          var terminationResponse = response;
          const dataString = response?.overriddenData ?? "";
          const pairs = dataString.split(":");
          const parsedData: Partial<IOverriddenData> = {};
          pairs.forEach((pair: any) => {
            if (pair.includes("=")) {
              const [key, value] = pair.split("=", 2);
              (parsedData as any)[key] =
                value === "false" ? false : value === "true" ? true : value;
            }
          });
          copyOverriddenData(parsedData, terminationResponse);

          const dataString1 = response?.postWithdrawalData ?? "";
          const pairs1 = dataString1.split(":");
          const parsedData1: Partial<IPostWithdrawalData> = {};

          pairs1.forEach((pair: any) => {
            if (pair.includes("=")) {
              const [key, value] = pair.split("=", 2);
              (parsedData1 as any)[key] =
                value === "false" ? false : value === "true" ? true : value;
            }
          });
          
          copyPostWithdrawalData(parsedData1, terminationResponse);
          setOverride(terminationResponse.ticketNumber != null ? true : false);
          setTerminationResult({...terminationResponse, isoverride: (terminationResponse.ticketNumber != null ? true : false)});
          props.setIsResultExists(true);
          props.hideLoading();
        }
      },
      (exception: any) => {}
    );
  };

  function copyPostWithdrawalData(source: any, target: any) {
    Object.keys(source).forEach((key) => {
      if (target.hasOwnProperty(key)) {
        target[key] = source[key];
      }
    });
  }

  function copyOverriddenData(source: any, target: any) {
    const mapping: { [key: string]: string } = {
      txtEA: "txtEa",
      txtEB: "txtEb",
      txtFA: "txtFa",
      txtFC: "txtFc",
      txtStep1FA: "txtStep1Fa",
      txtStep1FB: "txtStep1Fb",
      txtStep1FC: "txtStep1Fc",
      txtStep1FD: "txtStep1Fd",
      txtBoxH: "txtBoxh",
      txtBoxG: "txtBoxg",
      txt4JI: "txt4Ji",
      txt4JE: "txt4Je",
      txt4KE: "txt4Ke",
      txt4KI: "txt4Ki",
      txt5BoxH: "txt5Boxh",
      txt5BoxL: "txt5Boxl",
      txt5BoxM: "txt5Boxm",
      txt7K: "txt7k",
      txt7O: "txt7o",
      txt8boxB: "txt8boxb",
      txt8P: "txt8p",
      txt9BoxQ: "txt9Boxq",
      txt9BoxR: "txt9Boxr",
      txt9BoxF: "txt9Boxf",
      txt9Boxs: "txt9Boxs",
      txt9BoxT: "txt9Boxt",
    };
  
    Object.keys(mapping).forEach((sourceKey) => {
      const targetKey = mapping[sourceKey];
      if (sourceKey in source && targetKey in target) {
        target[targetKey] = source[sourceKey];
      }
    });
  }

  const GetIsClockHour = (enrollmentId: string) => {
    terminationApi.GetIsClockHour(enrollmentId).then(
      (response: any) => {
        if (response) {
          setIsClockHour(response);
        }
      },
      (exception: any) => {}
    );
  };

  const GetUserAllowedToOverrideR2T4Input = () => {
    terminationApi.GetIsUserAllowedToOverrideR2T4InputOrResultsInfo().then(
      (response: any) => {
        setUserAllowedToOverrideR2T4Input(response);
      },
      (exception: any) => {}
    );
  };

  const handleFieldOnChange = (fieldId: string, value: any) => {
    if (terminationResult) {
      let updatedModel = terminationResult;
      (updatedModel as any)[fieldId] = value;
      setTerminationResult({ ...updatedModel });
    }
  };

  const onSubmit = async (nextTab: any) => {
    if(terminationResult)
    {
      props.showLoading();
      if (override == false ) {
        if (terminationResult?.r2T4ResultsId == EmptyGuid) {
          terminationApi.PostR2T4Result(terminationResult).then(
            (response: any) => {
              if (response) {
                props.hideLoading();
                //setTerminationResult(response);                
                GetTerminationResult(terminationId);
                props.setSnackBar((props: any) => {
                  return {
                    variant: "success",
                    showSnackBar: true,
                    messageInfo:
                      "All the student termination details specified is saved",
                  };
                });
                if (nextTab) props.setTab(3);
              }
            },
            (exception: any) => {
              props.hideLoading();
            }
          );
        }
        else
        {
          terminationApi.PutR2T4Result(terminationResult).then(
            (response: any) => {
              if (response) {
                props.hideLoading();
                //setTerminationResult(response);
                GetTerminationResult(terminationId);
                props.setSnackBar((props: any) => {
                  return {
                    variant: "success",
                    showSnackBar: true,
                    messageInfo:
                      "All the student termination details specified is saved",
                  };
                });
                if (nextTab) props.setTab(3);
              }
            },
            (exception: any) => {
              props.hideLoading();
            }
          );
        }
      }
      else
      {
        if (terminationResult?.r2T4ResultsId == EmptyGuid)
        {
          terminationApi.PostResultOverride(terminationResult).then(
            (response: any) => {
              if (response) {
                props.hideLoading();
                //setTerminationResult(response);
                GetTerminationResult(terminationId);
                if(!nextTab)
                {
                  props.setSnackBar((props: any) => {
                    return {
                      variant: "success",
                      showSnackBar: true,
                      messageInfo:
                        "All the student termination details specified is saved",
                    };
                  });
                }                
                if (nextTab)
                {
                  props.setTab(3);
                } 
              }
            },
            (exception: any) => {
              props.hideLoading();
            }
          );
        }
        else
        {
          terminationApi.PutResultOverride(terminationResult).then(
            (response: any) => {
              if (response) {
                props.hideLoading();
                //setTerminationResult(response);
                GetTerminationResult(terminationId);
                if(!nextTab)
                  {
                    props.setSnackBar((props: any) => {
                      return {
                        variant: "success",
                        showSnackBar: true,
                        messageInfo:
                          "All the student termination details specified is saved",
                      };
                    });
                  }  
                  if (nextTab) props.setTab(3);
              }
            },
            (exception: any) => {
              props.hideLoading();
            }
          );
        }
      }      
    }    
  };

  const handleOverrideChange = (value: boolean) => {
    if (value == false) {
      props.setConfirmationDialog({
        open: true,
        message: "All the changes made would be discarded. Are you sure?",
        onOk: () => {
          terminationApi
          .DeleteResultOverride(terminationId)
          .then(
            (response: any) => {
              if (response) {
                GetR2T4Result();
                setOverride(value);
              }
            },
            (exception: any) => {}
          );         
        },
        onCancel: () => setLoading(false),
      });
    } else {
      setOverride(value);
    }
  };

  const onApprove = () => {    
  };

  const onCancel = () => {
    props.setConfirmationDialog({
      open: true,
      message: "Are you sure you want to terminate the (" + student?.studentNumber + ") " + student?.studentName + " from " + student?.currentProgramVersion,
      onOk: () => {
        deleteTermination();
      },
      onCancel: () => setLoading(false),
    });
  };

  const deleteTermination = () => {
    if (terminationId != EmptyGuid) {
      props.deleteTermination(terminationId);
    }
  };

  


  return (
    <div className={classes.root}>
      <Grid container direction="row" spacing={1}>
        <Grid item md={3} sm={3} xs={12}>
          <b>Student: </b> ({student?.studentNumber}) {student?.studentName}
        </Grid>
        <Grid item md={3} sm={3} xs={12}>
          <b>SSN: </b>
          {student?.ssn}
        </Grid>
        <Grid item md={3} sm={3} xs={12}>
          <b>Date Form Completed: </b>
          {new Date(
            enrollment?.dateDetermined ?? new Date()
          ).toLocaleDateString()}
        </Grid>
        <Grid item md={3} sm={3} xs={12}>
          <b>Date of determination: </b>
          {new Date(
            studentTermination?.dateWithdrawalDetermined ?? new Date()
          ).toLocaleDateString()}
        </Grid>
        <Grid
          item
          md={6}
          sm={6}
          xs={12}
          style={{ paddingTop: 20, paddingBottom: 20 }}
        >
          <FormControl
            component="fieldset"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <FormLabel
              component="legend"
              style={{ marginRight: "1rem", whiteSpace: "nowrap" }}
            >
              <b>Period used for calculation : </b>
            </FormLabel>
            <RadioGroup
              aria-label="calculationPeriodTypeId"
              name="calculationPeriodTypeId"
              value={enrollment?.calculationPeriodTypeId ?? ""}
              //onChange={handleSelectedPeriod}
              style={{ display: "flex", flexDirection: "row" }}
            >
              {periodTypes?.map((pt: IPeriodTypes) => (
                <FormControlLabel
                  key={pt.value}
                  disabled={true}
                  value={pt.value}
                  control={<Radio />}
                  label={pt.text}
                  labelPlacement="end"
                  style={{ marginRight: "1rem" }}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid
          item
          md={6}
          sm={6}
          xs={12}
          style={{ paddingTop: 20, paddingBottom: 20 }}
        >
          <FormControl
            component="fieldset"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <FormLabel
              component="legend"
              style={{ marginRight: "1rem", whiteSpace: "nowrap" }}
            >
              <b>Program type :</b>
            </FormLabel>
            <RadioGroup
              aria-label="calculationPeriodTypeId"
              name="calculationPeriodTypeId"
              value={isClockHour == true ? "clockHour" : "creditHour"}
              //onChange={handleSelectedPeriod}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <FormControlLabel
                key={"clockHour"}
                disabled={true}
                value={"clockHour"}
                control={<Radio />}
                label={"Clock Hour"}
                labelPlacement="end"
                style={{ marginRight: "1rem" }}
              />
              <FormControlLabel
                key={"creditHour"}
                disabled={true}
                value={"creditHour"}
                control={<Radio />}
                label={"Credit Hour"}
                labelPlacement="end"
                style={{ marginRight: "1rem" }}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={1}>
        <ResultsTab1
          model={terminationResult}
          setModel={setTerminationResult}
          override={override}
        />
        <ResultsTab2
          model={terminationResult}
          setModel={setTerminationResult}
          override={override}
        />
        <ResultsTab3
          model={terminationResult}
          setModel={setTerminationResult}
          override={override}
        />
        <ResultsTab4
          model={terminationResult}
          setModel={setTerminationResult}
          override={override}
        />
        <ResultsTab5
          model={terminationResult}
          setModel={setTerminationResult}
          override={override}
        />
        <ResultsTab6
          model={terminationResult}
          setModel={setTerminationResult}
          override={override}
        />
        <ResultsTab7
          model={terminationResult}
          setModel={setTerminationResult}
          override={override}
        />
        <ResultsTab8
          model={terminationResult}
          setModel={setTerminationResult}
          override={override}
        />
        <ResultsTab9
          model={terminationResult}
          setModel={setTerminationResult}
          override={override}
        />
        <ResultsTab10
          model={terminationResult}
          setModel={setTerminationResult}
          override={override}
        />
        <ResultsTab11
          model={terminationResult}
          setModel={setTerminationResult}
          override={override}
        />
        <Grid item md={12} sm={12} xs={12}>
          <Box>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Ticket # *</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Checkbox
                            //checked={terminationResult?.isoverride}
                            checked={override}
                            disabled={
                              userAllowedToOverrideR2T4Input == true
                                ? false
                                : true
                            }
                            onChange={(e) => {
                             /*  handleFieldOnChange(
                                "isoverride",
                                e.target.checked
                              ); */
                              handleOverrideChange(e.target.checked);
                            }}
                            color="primary"
                          />
                        }
                        label="Override"
                      />
                    </TableCell>
                    <TableCell>
                      <InputField
                        type={InputType.TEXT}
                        label=""
                        name="ticketNumber"
                        key="ticketNumber"
                        defaultValue={terminationResult?.ticketNumber}
                        onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                          handleFieldOnChange("ticketNumber", v.target.value);
                        }}
                        error={!!data.errors.ticketNumber}
                        inputRef={data.register({
                          required: override == true ? true : false,
                        })}
                        helperText={
                          data.errors.ticketNumber
                            ? (data.errors.ticketNumber as FieldError).message
                            : undefined
                        }
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
      <Grid spacing={1}>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          mt={2}
          mb={2}
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={() => props.setTab(1)}
            style={{ marginRight: "8px" }}
          >
            &lt; Back
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onSubmit(true)}
            style={{ marginRight: "8px" }}
          >
            Next &gt;
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onSubmit(false)}
            style={{ marginRight: "8px" }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onCancel()}
            style={{ marginRight: "8px" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onApprove()}
            style={{
              marginRight: "8px",
              backgroundColor: "green",
              color: "white",
            }}
          >
            Approve Termination
          </Button>
        </Box>
      </Grid>
    </div>
  );
};

export default R2T4InputTab;
