import React, { useState, useEffect } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import {
  IStudentTermination,
  ITerminationEnrollment,
  ITerminationStatusChange,
  IStudentEnrollments,
  ITerminationDetail,
  IR2T4AdditionalDetail,
  ITerminationReportProps,
  IR2T4CalculationSummary,
  IList,
} from "../../../interfaces/student/termination/IStudentTermination";
import * as terminationApi from "../../../api/student/termination/studentTerminationApi";
import { StudentProfile } from "../../../interfaces/student/profile/StudentProfile";
import { EmptyGuid } from "../../../utils/constants";
import MuiAccordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { withStyles } from "@material-ui/core/styles";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import RefundPolicy from "./RefundPolicy";

const Accordion = withStyles({
  root: {
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      minHeight: "100%",
      paddingTop: theme.spacing(3),
      overflowX: "hidden",
      overflowY: "auto",
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
    startDateField: {
      width: "100%",
    },
  })
);
interface ITerminationProps {
  student: StudentProfile;
  enrollment: IStudentEnrollments | undefined;
  enrollmentId: string;
  terminationId: string;
  campusId: string;
  setConfirmationDialog: (params: any) => void;
  setSnackBar: (params: any) => void;
  setTab: (params: any) => void;
  deleteTermination: (params: any) => void;
  showLoading: any;
  hideLoading: any;
}

const ApproveTerminationTab = (props: ITerminationProps) => {
  const { campusId, enrollmentId, terminationId, student, enrollment } = props;
  const classes = useStyles({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [terminationDetail, setTerminationDetail] =
    React.useState<ITerminationDetail>();
  const [additionalDetail, setAdditionalDetail] =
    React.useState<IR2T4AdditionalDetail>();
  const [calculationSummary, setCalculationSummary] =
    React.useState<IR2T4CalculationSummary>();
  const [termination, setTermination] = React.useState<IStudentTermination>();
  const [userAllowedToOverrideR2T4Input, setUserAllowedToOverrideR2T4Input] =
    React.useState<boolean>(false);
  const data = useForm<any>({ mode: "onBlur" });

  useEffect(() => {
    GetTerminationDetails(terminationId);
    GetUserAllowedToOverrideR2T4Input();
  }, [terminationId]);

  useEffect(() => {
    getTermination(enrollmentId);
  }, [enrollmentId]);

  const getCurrencyFormattedValue = (
    value: any,
    addBracesForNegativeNumbers = true
  ) => {
    let numberVal = parseFloat(value?.toString().trim() || "0.00");

    const isNegative = addBracesForNegativeNumbers && numberVal < 0;
    if (isNegative) {
      numberVal = Math.abs(numberVal);
    }

    return `${isNegative ? "(" : ""}$${formatNumberAsCurrency(numberVal)}${
      isNegative ? ")" : ""
    }`;
  };
  const formatNumberAsCurrency = (num: any) =>
    num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  let index = 1;

  const GetTerminationDetails = (terminationId: string) => {
    props.showLoading();
    terminationApi.GetTerminationDetails(terminationId).then(
      (response: ITerminationDetail) => {
        if (response) {
          setTerminationDetail(response);
          setAdditionalDetail(response?.additionalInformationDetail);
          setCalculationSummary(response?.r2T4CalculationSummaryDetail);
        }
        props.hideLoading();
      },
      (exception: any) => {
        props.hideLoading();
      }
    );
  };

  const getTermination = (enrollmentId: any) => {
    props.showLoading();
    terminationApi.GetStudentTermination(enrollmentId).then(
      (response: any) => {
        if (response) {
          setTermination(response);
          props.hideLoading();
        }
      },
      (exception: any) => {
        props.hideLoading();
      }
    );
  };

  const generateStudentSummaryReport = async (
    reportPath: string
  ): Promise<any> => {
    props.showLoading();

    var reportProps: ITerminationReportProps = {
      CampusId: campusId,
      StudentTerminationId: terminationId,
      isPreviewReport: false,
      reportCategory: "Termination",
      reportExtension: ".pdf",
      reportName: "Termination Details",
    };

    var reportName = `(${student?.studentNumber}) ${student?.studentName}_${enrollment?.enrollmentId}`;

    try {
      let response;
      if (reportPath === "RefundCalculation") {
        response = await terminationApi.generateRefundReport(
          reportPath,
          reportName,
          reportProps
        );
      } else {
        response = await terminationApi.generateStudentSummaryReport(
          reportPath,
          reportName,
          reportProps
        );
      }

      if (response && response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${reportName}_${reportPath}.pdf`);
        document.body.appendChild(link);
        link.click();
      }

      return response;
    } catch (error) {
      console.error("Error generating report:", error);
      return Promise.reject(error);
    } finally {
      props.hideLoading();
    }
  };

  const GetUserAllowedToOverrideR2T4Input = () => {
    terminationApi.GetIsUserAllowedToOverrideR2T4InputOrResultsInfo().then(
      (response: any) => {
        setUserAllowedToOverrideR2T4Input(response);
      },
      (exception: any) => {}
    );
  };

  const onApprove = () => {
    props.setConfirmationDialog({
      open: true,
      message:
        "Are you sure you want to terminate the (" +
        student?.studentNumber +
        ") " +
        student?.studentName +
        " from " +
        student?.currentProgramVersion +
        "?",
      onOk: () => {
        handleStudentTermination();
      },
      onCancel: () => setLoading(false),
    });
  };

  const handleStudentTermination = async () => {
    try {
      props.showLoading();
      const reportPaths = [
        "StudentTerminationSummary",
        "R2T4ResultsClockHour",
        "RefundCalculation",
      ];

      var enrollmentDetail: ITerminationEnrollment = {
        enrollmentId: enrollmentId,
        statusCodeId: termination?.statusCodeId ?? "",
        dateDetermined: enrollment?.dateDetermined ?? new Date(),
        lastDateAttended: enrollment?.lastDateAttended ?? new Date(),
        calculationPeriodType: enrollment?.calculationPeriodTypeId ?? "",
        dropReasonId: termination?.dropReasonId ?? "",
        isPerformingR2T4Calculator:
          termination?.isPerformingR2T4Calculator ?? false,
        terminationId: terminationId,
        updatedBy: "",
        createdBy: "",
      };

      var enrollmentStatus: ITerminationStatusChange = {
        studentEnrollmentId: enrollmentId,
        campusId: campusId,
        newStatusId: termination?.statusCodeId ?? "",
        dropReasonId: termination?.dropReasonId ?? "",
        isReversal: false,
        dateOfChange: terminationDetail?.dateOfDetermination ?? new Date(),
        originalStatusId: enrollment?.statusCodeId ?? "",
        isTransferred: false,
        toProgram: null,
        toProgramVersion: null,
        transferHours: null,
        transferDate: null,
      };

      await Promise.all(
        reportPaths.map((reportPath) =>
          generateStudentSummaryReport(reportPath)
        )
      );

      const [response, response1, response2] = await Promise.all([
        terminationApi.ProcessRefund(terminationId),
        terminationApi.PutEnrollmentDetails(enrollmentDetail),
        terminationApi.PostStudentStatusChanges(enrollmentStatus),
      ]);

      if (response?.resultStatus === 0 && response1 && response2) {
        const successMessage = `(${student?.studentNumber}) ${student?.studentName} is terminated from ${student?.currentProgramVersion}`;

        props.setConfirmationDialog({
          open: true,
          title: "Approved",
          confirmOnly: true,
          message: successMessage,
          onOk: onOk,
        });
      }
    } catch (error) {
      props.hideLoading();
      console.error("Error handling student termination:", error);
      props.setSnackBar(() => ({
        variant: "error",
        showSnackBar: true,
        messageInfo:
          "An error occurred during the student termination process.",
      }));
    }
  };

  const onOk = () => {
    window.location.href =
      "/dashboard?campusId=" +
      props.campusId +
      "&studentId=" +
      student.studentId;
  };

  const onCancel = async (confirm?: boolean) => {
    props.setConfirmationDialog({
      open: true,
      message:
        "Are you sure you want to cancel the student termination process? All current data entered, including the previously saved data, will be lost",
      onOk: () => deleteTermination(),
      onCancel: () => setLoading(false),
    });
  };

  const deleteTermination = () => {
    if (terminationId != EmptyGuid) {
      props.deleteTermination(terminationId);
    }
  };

  return (
    <div className={classes.root}>
      <Grid container direction="row" spacing={1}>
        <Grid item md={8} sm={8} xs={12}>
          <Grid container direction="row" spacing={1}>
            <Grid item md={4} sm={4} xs={12}>
              <b>Student: </b> ({student?.studentNumber}) {student?.studentName}
            </Grid>
            <Grid item md={4} sm={4} xs={12}>
              <b>SSN: </b>
              {student?.ssn}
            </Grid>
            <Grid item md={4} sm={4} xs={12}>
              <b>Enrollment: </b>
              {enrollment?.programVersionDescription}
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ArrowDownward />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>
                <b>Termination Detail</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <Box>
                <TableContainer component={Paper}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>Enrollment:</TableCell>
                        <TableCell>
                          {terminationDetail?.enrollmentName}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Status:</TableCell>
                        <TableCell>{terminationDetail?.status}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Drop reason:</TableCell>
                        <TableCell>{terminationDetail?.dropReason}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Last date attended:</TableCell>
                        <TableCell>
                          {terminationDetail?.lastDateAttended}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Withdrawal date:</TableCell>
                        <TableCell>
                          {terminationDetail?.withdrawalDate}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Date of determination:</TableCell>
                        <TableCell>
                          {terminationDetail?.dateOfDetermination}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="right">
                          <Button
                            onClick={() => {
                              generateStudentSummaryReport(
                                "StudentTerminationSummary"
                              );
                            }}
                            variant="contained"
                            color="secondary"
                          >
                            Preview
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </AccordionDetails>
          </Accordion>
          {terminationDetail?.isR2T4 == true ? (
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ArrowDownward />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography>
                  <b>R2T4 Calculation Results</b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{ display: "flex", flexDirection: "column", gap: 2 }}
              >
                <Box>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>Total charges:</TableCell>
                          <TableCell>
                            {
                              terminationDetail?.r2T4CalculationSummaryDetail
                                ?.totalCharges
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Total Title IV aid:</TableCell>
                          <TableCell>
                            {
                              terminationDetail?.r2T4CalculationSummaryDetail
                                ?.totalTitleIvAid
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Total Title IV aid disbursed:</TableCell>
                          <TableCell>
                            {
                              terminationDetail?.r2T4CalculationSummaryDetail
                                ?.totalTitleIvAidDisbursed
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Percentage of Title IV aid earned:
                          </TableCell>
                          <TableCell>
                            {
                              terminationDetail?.r2T4CalculationSummaryDetail
                                ?.percentageOfTitleIvAidEarned
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Total Title IV aid to return:</TableCell>
                          <TableCell>
                            {
                              terminationDetail?.r2T4CalculationSummaryDetail
                                ?.totalTitleIvAidToReturn
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Amount to be returned by school:{" "}
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <b>Total</b>
                          </TableCell>
                          <TableCell>
                            <b>
                              {
                                terminationDetail?.r2T4CalculationSummaryDetail
                                  ?.totalAmountToBeReturnedBySchool
                              }
                            </b>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Amount to be returned by student:
                          </TableCell>
                          <TableCell>
                            {terminationDetail?.r2T4CalculationSummaryDetail?.amountToBeReturnedByStudent?.map(
                              (st: IList, index: any) => (
                                <TableCell key={index}>{st.text}</TableCell>
                              )
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <b>Total</b>
                          </TableCell>
                          <TableCell>
                            <b>
                              {
                                terminationDetail?.r2T4CalculationSummaryDetail
                                  ?.totalAmountToBeReturnedByStudent
                              }
                            </b>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell align="right">
                            <Button
                              onClick={() => {
                                generateStudentSummaryReport(
                                  "R2T4ResultsClockHour"
                                );
                              }}
                              variant="contained"
                              color="secondary"
                            >
                              Preview
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </AccordionDetails>
            </Accordion>
          ) : (
            ""
          )}
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ArrowDownward />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>
                <b>Refund calculation</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <RefundPolicy
                terminationId={terminationId}
                page="termination"
                pageKey={1}
                showLoading={props.showLoading}
                hideLoading={props.hideLoading}
                setConfirmationDialog={props.setConfirmationDialog}
                showFutureChargesMessage={false}
              />
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="right">
                      <Button
                        onClick={() => {
                          generateStudentSummaryReport("RefundCalculation");
                        }}
                        variant="contained"
                        color="secondary"
                      >
                        Preview
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ArrowDownward />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>
                <b>Additional information</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <Box>
                <div>
                  {!additionalDetail?.isClockHour &&
                    additionalDetail?.isNotRequiredToTakeAattendance && (
                      <div>
                        {index++}. Step 2: Percentage of Title IV aid earned was
                        marked as 50% because the school is not required to take
                        attendance for this program. This was indicated by
                        USER_FNAME_LNAME under the 'Percentage of period
                        completed' section on the 'R2T4 Input' tab.
                      </div>
                    )}

                  {!additionalDetail?.isClockHour &&
                    additionalDetail?.isAttendance100Percent && (
                      <div>
                        {index++}. As the result of division of Completed
                        days/Total days is greater than 60%, Box H under
                        Subsection H of 'Step 2: Percentage of Title IV aid
                        earned' was marked as 100%.
                      </div>
                    )}

                  {additionalDetail?.isClockHour &&
                    additionalDetail?.isAttendance100Percent && (
                      <div>
                        {index++}. As the result of division of Hours scheduled
                        to complete/Total hours is greater than 60%, Box H under
                        Subsection H of 'Step 2: Percentage of Title IV aid
                        earned' was marked as 100%.
                      </div>
                    )}
                  {additionalDetail?.r2T4InputFieldsWithOverriddenValues && additionalDetail?.r2T4InputFieldsWithOverriddenValues?.length > 0 ? (
                    <div>
                      <div>
                        {index++}. Following input fields were overridden by{" "}
                        {additionalDetail?.overriddenUserNameInput}. See ticket
                        number{" "}
                        <a id="aInputTicketNumber" target="_blank">
                          {additionalDetail?.ticketNumberInput}
                        </a>
                        for further details.
                      </div>
                      <div>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Field</TableCell>
                              <TableCell>Calculated by application</TableCell>
                              <TableCell>Overridden values</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {additionalDetail?.r2T4InputFieldsWithOverriddenValues.map(
                              (item, index) => (
                                <TableRow>
                                  <TableCell>{item?.fieldName}</TableCell>
                                  <TableCell>{item?.resultValue}</TableCell>
                                  <TableCell>
                                    {item?.overriddenResultValue}
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {additionalDetail?.r2T4ResultFieldsWithOverriddenValues && additionalDetail?.r2T4ResultFieldsWithOverriddenValues?.length > 0 ? (
                    <div>
                      <div>
                        {index++}. Following input fields were overridden by{" "}
                        {additionalDetail?.overriddenUserNameInput}. See ticket
                        number{" "}
                        <a id="aInputTicketNumber" target="_blank">
                          {additionalDetail?.ticketNumberInput}
                        </a>
                        for further details.
                      </div>
                      <div>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Field</TableCell>
                              <TableCell>Calculated by application</TableCell>
                              <TableCell>Overridden values</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {additionalDetail?.r2T4ResultFieldsWithOverriddenValues.map(
                              (item, index) => (
                                <TableRow>
                                  <TableCell>{item?.fieldName}</TableCell>
                                  <TableCell>{item?.resultValue}</TableCell>
                                  <TableCell>
                                    {item?.overriddenResultValue}
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {additionalDetail?.overlappingLoanDetails && additionalDetail?.overlappingLoanDetails?.length > 0 ? (
                    <div>
                      <div>
                        {index++}. Overlapping loans - Student withdrew during
                        payment period{" "}
                        {additionalDetail?.withdrawalPaymentPeriod}.{" "}
                        {additionalDetail?.withdrawalPeriodBegin +
                          " - " +
                          additionalDetail?.withdrawalPeriodEnd}
                      </div>
                      <div>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Award Type</TableCell>
                              <TableCell>Loan Period Begin</TableCell>
                              <TableCell>Loan Period End</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {additionalDetail?.overlappingLoanDetails.map(
                              (item, index) => (
                                <TableRow>
                                  <TableCell>
                                    {item?.fundSourceDescription}
                                  </TableCell>
                                  <TableCell>{item?.loanPeriodBegin}</TableCell>
                                  <TableCell>{item?.loanPeriodEnd}</TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {additionalDetail?.aidBeforeDateOfDetermination && additionalDetail?.aidBeforeDateOfDetermination?.length > 0 ? (
                    <div>
                      <div>
                        {index++}.Following aid were disbursed after the
                        Withdrawal Date but before the Date of Determination
                        thus they were included as disbursed aid amount as the
                        student has withdrawn due to COVID-19.
                      </div>
                      <div>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Aid</TableCell>
                              <TableCell>Disbursement Period</TableCell>
                              <TableCell>Disbursement Date</TableCell>
                              <TableCell>Disbursed Amount</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {additionalDetail?.aidBeforeDateOfDetermination.map(
                              (item, index) => (
                                <TableRow>
                                  <TableCell>{item?.aid}</TableCell>
                                  <TableCell>
                                    {item?.disbursementPeriod}
                                  </TableCell>
                                  <TableCell>
                                    {item?.disbursementDate}
                                  </TableCell>
                                  <TableCell>
                                    {item?.disbursementAmount}
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  {additionalDetail?.postWithdrawalDisbursementAmount &&
                  parseFloat(
                    additionalDetail?.postWithdrawalDisbursementAmount
                  ) > 0 ? (
                    <React.Fragment>
                      <div>
                        {index++}. As the student has withdrawn with a drop
                        reason of COVID-19 the Post Withdrawal Disbursement
                        amount calculated as per R2T4 calculation is increased
                        to total amount of aid that could have been disbursed:{" "}
                        {getCurrencyFormattedValue(
                          additionalDetail?.postWithdrawalDisbursementAmount
                        )}
                        .
                      </div>
                      <div>
                        {index++}. R2T4 Return amount calculated was not
                        included while calculating Institutional Refund for the
                        student as for the student it is not required to return
                        the unearned Title IV amount.
                      </div>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  {additionalDetail?.titleIvGrantLessThan50Dollar && additionalDetail?.titleIvGrantLessThan50Dollar?.length > 0 ? (
                    <React.Fragment>
                      <div>
                        {index++}. The student is not responsible for returning
                        the following funds because the amount to return is less
                        than or equal to $50:
                      </div>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Title IV grant Program</TableCell>
                            <TableCell>Amount to return</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {additionalDetail?.titleIvGrantLessThan50Dollar?.map(
                            (item, index) => (
                              <TableRow>
                                <TableCell>{item?.text}</TableCell>
                                <TableCell>{item?.value}</TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </div>
                {additionalDetail?.isTuitionByPaymentPeriod &&
                additionalDetail?.calculationPeriodTypeId == "0" &&
                (calculationSummary?.postWithdrawalDisbursement === "$0.00" ||
                  calculationSummary?.postWithdrawalDisbursement === "") ? (
                  <React.Fragment>
                    <div>
                      {index++}. The Tuition is not charged by payment period
                      and ‘Payment Period’ is the Period used for calculation.
                      As a result, the higher of Box L or Box E minus credit
                      balance refunded to student was used for Box L under
                      subsection N of ‘Step 5: Amount of unearned Title IV aid
                      due from the school'. The following values were used in
                      this comparison:
                    </div>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              Total Institutional charges (Box L):
                            </TableCell>
                            <TableCell>
                              {
                                terminationDetail?.r2T4CalculationSummaryDetail
                                  ?.totalCharges
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              Credit balance refunded to student:
                            </TableCell>
                            <TableCell>
                              {
                                terminationDetail?.additionalInformationDetail
                                  ?.creditBalanceRefunded
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              Total Title IV aid disbursed for the period (Box
                              E):
                            </TableCell>
                            <TableCell>
                              {
                                terminationDetail?.r2T4CalculationSummaryDetail
                                  ?.totalTitleIvAid
                              }
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </React.Fragment>
                ) : (
                  ""
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid spacing={1}>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            mt={2}
            mb={2}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={() => props.setTab(3)}
              style={{ marginRight: "8px" }}
            >
              &lt; Back
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => props.setTab(4)}
              style={{ marginRight: "8px" }}
            >
              Next &gt;
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => onCancel(true)}
              style={{ marginRight: "8px" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => onApprove()}
              style={{
                marginRight: "8px",
                backgroundColor: "green",
                color: "white",
              }}
            >
              Approve Termination
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default ApproveTerminationTab;
