import React from 'react';
import {
	makeStyles,
	createStyles,
	Card,
	Tabs,
	Tab,
} from '@material-ui/core';
import ProgramVersionDetailsTab from './Details/ProgramVersionDetailsTab';
import ProgramVersionCharginMethodTab from './ChargingMethod/ProgramVersionCharginMethodTab';
import ProgramVersionDefinitionTab from './Definition/ProgramVersionDefinitionTab';
import ProgramVersionScheduleTab from './Schedule/ProgramVersionScheduleTab';
import CustomCardTitle from '../../../../../interfaces/common/card/CustomCardTitle';
import { EmptyGuid } from '../../../../../utils/constants';
import IllinoisTranscriptSettings from './IllinoisTranscriptSettings';
import { system } from '../../../../../constants/system/systemConstants';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme: any) =>
	createStyles({
		cardTitle: {
			fontSize: 17,
			color: theme.palette.black,
			textDecoration: 'bold',
			backgroundColor: theme.palette.site.secondary,
			padding: theme.spacing(1, 1),
			marginBottom: theme.spacing(1),
		},
		headerText: {
			paddingLeft: theme.spacing(1),
			paddingBottom: theme.spacing(0),
		},
		cardContent: {
			padding: theme.spacing(1, 3),
			height: '100%',
		},
		cardWrapper: {
		},
		tabController: {
			backgroundColor: 'transparent',
			textTransform: 'uppercase',
		},
		tabIndicator: {
			backgroundColor: theme.palette.site.primary,
		},
		tabSelected: { fontWeight: 'bold' },
	})
);
type ProgramVersionDetailsCardProps = {
	setSnackBar: any;
	programVersionId: string;
	newProgramVersionName: string;
	clearNewProgramProgramVersion: any;
	programId?: string;
	setRefreshData: any;
	refreshDetailData: any;
};
const ProgramVersionDetailsCard = (props: ProgramVersionDetailsCardProps) => {
	const classes = useStyles({});
	const [tab, setTab] = React.useState(0);

	const handleChange = (event: any, newValue: any) => {
		setTab(newValue);
	};
	const appSettings = useSelector((state: any) => {
		return state.settings.settings;
	});
	const isIllinoisEnabled = (appSettings[system.settings.enableIllinoisTranscript] as string || 'no').toLowerCase() === 'yes';
	return (
		<Card square={true} className={classes.cardWrapper}>
			<CustomCardTitle title={'Program Version Details'} />
			<Tabs
				value={tab}
				indicatorColor="primary"
				textColor="primary"
				onChange={handleChange}
				aria-label="disabled tabs example"
				variant="fullWidth"
				classes={{
					indicator: classes.tabIndicator,
				}}
			>
				<Tab label="Details" classes={{ selected: classes.tabSelected }} />
				<Tab label="Definition" classes={{ selected: classes.tabSelected }} disabled={props.programVersionId === EmptyGuid} />
				<Tab label="Schedule" classes={{ selected: classes.tabSelected }} disabled={props.programVersionId === EmptyGuid} />
				{/* <Tab
					label="Charging Method"
					classes={{ selected: classes.tabSelected }} disabled={props.programVersionId === EmptyGuid}
				/> */}
				{isIllinoisEnabled && <Tab
					label="Illinois Report Configuration(s)"
					classes={{ selected: classes.tabSelected }} disabled={props.programVersionId === EmptyGuid}
				/>}
			</Tabs>
			<ProgramVersionDetailsTab
				hidden={tab !== 0}
				clearNewProgramProgramVersion={props.clearNewProgramProgramVersion}
				newProgramVersionName={props.newProgramVersionName}
				programVersionId={props.programVersionId}
				setSnackBar={props.setSnackBar}
				programId={props.programId}
				setRefreshData={props.setRefreshData}
				refreshDetailData={props.refreshDetailData}
			/>
			<ProgramVersionDefinitionTab
				hidden={tab !== 1}
				programVersionId={props.programVersionId}
				setSnackBar={props.setSnackBar}
				programId={props.programId}
			/>
			<ProgramVersionScheduleTab
				hidden={tab !== 2}
				programVersionId={props.programVersionId}
				setSnackBar={props.setSnackBar}
			/>
			<ProgramVersionCharginMethodTab
				hidden={tab !== 3}
				programVersionId={props.programVersionId}
				setSnackBar={props.setSnackBar}
			/>
			{isIllinoisEnabled && <IllinoisTranscriptSettings
				hidden={tab !== 4}
				programVersionId={props.programVersionId}
				programId={props.programId}
			/>}
		</Card>
	);
};

export default ProgramVersionDetailsCard;
