import React, { useState, useEffect } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  Switch,
  RadioGroup,
  Radio,
  Typography,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { FormContext, useForm, FieldError } from "react-hook-form";
import {
  IStudentTermination,
  IStudentEnrollments,
  IR2T4Step1Input,
  IR2T4Input,
  IProgramVersionDetail,
  IScheduleHoursToComplete,
  ITerminationResult,
} from "../../../interfaces/student/termination/IStudentTermination";
import MuiAccordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import CustomCardTitle from "../../../interfaces/common/card/CustomCardTitle";
import { withStyles } from "@material-ui/core/styles";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import InfoIcon from "@material-ui/icons/Info";
import IconButton from "@material-ui/core/IconButton";
import CurrencyInput from "../../../components/_Layout/Inputs/CurrencyInput";
import InputField from "../../../components/_Layout/Inputs/InputField";
import { InputType } from "../../../constants/uiConstants/inputConstants";
import { colors } from "@material-ui/core";
import { TabUnselected } from "@material-ui/icons";

const Accordion = withStyles({
  root: {
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      minHeight: "100%",
      paddingTop: theme.spacing(3),
      overflowX: "hidden",
      overflowY: "auto",
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
    startDateField: {
      width: "100%",
    },
    leftLabel: {
      width: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: "black",
      color: "white",
      textAlign: "center",
      marginRight: theme.spacing(1),
      display: "inline-block",
      height: "31px",
      paddingTop: "6px",
    },
    rightLabel: {
      width: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: "black",
      color: "white",
      textAlign: "center",
      marginRight: "-19px",
      display: "inline-block",
      position: "absolute",
      right:0,
      height: "31px",
      paddingTop: "6px",
    },
    leftLabelBox: {
      width: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
      textAlign: "center",
      marginRight: theme.spacing(1),
      display: "inline-block",
      marginLeft: "-23px",
    },
  })
);

interface ITerminationProps {
  model: ITerminationResult | undefined;
  setModel: (params: any) => void;
  override: boolean;
}

const ResultsTab11 = (props: ITerminationProps) => {
  const classes = useStyles({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [enrollment, setEnrollment] = React.useState<IStudentEnrollments>();
  const [model, setModel] = React.useState<ITerminationResult>();
  const [override, setOverride] = React.useState<boolean>(props.override);
  const data = useForm<any>({ mode: "onBlur" });

  useEffect(() => {
    setModel(props.model);
    setOverride(props.override);
  }, [props]);

  const handleFieldOnChange = (fieldId: string, value: any) => {
    if (model) {
      let updatedModel = model;
      (updatedModel as any)[fieldId] = value;
      setModel({ ...updatedModel });
    }
  };

  const handleInstructorCheck = (event: any) => {
    var ch = event.target.checked;
  };

  return (
    <React.Fragment>
      <Grid item md={12} sm={12} xs={12}>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ArrowDownward />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>
              <b>11. POST-WITHDRAWAL DISBURSEMENT TRACKING SHEET</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <Box>
              <Grid container direction="row" spacing={1}>
                <Grid item md={12} sm={12} xs={12}>
                  <Grid container direction="row" spacing={1}>
                    <Grid item md={9} sm={9} xs={12}>
                      <b>I. Amount of Post-withdrawal Disbursement (PWD)</b>
                      <div>
                        Amount from "Box J" of the Treatment of Title IV Funds
                        When a Student Withdraws worksheet
                      </div>
                      <b>
                        II. Outstanding Charges for Educationally Related
                        Expenses Remaining on Student's Account
                      </b>
                      <div>
                        Total Outstanding charges Scheduled to be Paid from PWD
                      </div>
                      <div>
                        <i>(Note: Prior-year charges cannot exceed $200.)</i>
                      </div>
                    </Grid>
                    <Grid item md={3} sm={3} xs={12}>
                      <table>
                        <TableBody>
                          <TableRow>
                            <TableCell align="center">
                            <b>Box 1</b>
                              <InputField
                                type={InputType.NUMBER}
                                label=""
                                name="txtPWD"
                                key="txtPWD"
                                decimal
                                thousandSeparator
                                 disabled={override == true ? false : true}
                                defaultValue={model?.txtPWD}
                                onBlur={(
                                  v: React.FocusEvent<HTMLInputElement>
                                ) => {
                                  handleFieldOnChange(
                                    "txtPWD",
                                    v.target.value
                                  );
                                }}
                                error={!!data.errors.txtPWD}
                                inputRef={data.register({
                                  required: "Field is required.",
                                })}
                                InputProps={{
                                  startAdornment: (
                                    <React.Fragment>
                                      <div className={classes.leftLabel}>$</div>
                                    </React.Fragment>
                                  ),
                                }}
                                helperText={
                                  data.errors.txtPWD
                                    ? (data.errors.txtPWD as FieldError)
                                        .message
                                    : undefined
                                }
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="center">
                              <b>Box 2</b>
                              <InputField
                                type={InputType.NUMBER}
                                label=""
                                name="txtPWDBox2"
                                key="txtPWDBox2"
                                decimal
                                thousandSeparator
                                 disabled={override == true ? false : true}
                                defaultValue={model?.txtPWDBox2}
                                onBlur={(
                                  v: React.FocusEvent<HTMLInputElement>
                                ) => {
                                  handleFieldOnChange(
                                    "txtPWDBox2",
                                    v.target.value
                                  );
                                }}
                                error={!!data.errors.txtPWDBox2}
                                inputRef={data.register({
                                  required: "Field is required.",
                                })}
                                InputProps={{
                                  startAdornment: (
                                    <React.Fragment>
                                      <div className={classes.leftLabel}>$</div>
                                    </React.Fragment>
                                  ),
                                }}
                                helperText={
                                  data.errors.txtPWDBox2
                                    ? (data.errors.txtPWDBox2 as FieldError)
                                        .message
                                    : undefined
                                }
                              />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </table>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <b>
                    III. Post-withdrawal Disbursement Offered Directly to
                    Student and/or Parent
                  </b>
                  <div>
                    From the total Post-withdrawal Disbursement due (Box 1),
                    subtract the Post-withdrawal Disbursement to be credited to
                    the student's account (Box 2). This is the amount you must
                    make to the student (grant) or offer to the student or
                    parent (Loan) as a Direct Disbursement.
                  </div>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          <b>Box 1</b>
                        </TableCell>
                        <TableCell style={{ width: 10 }}></TableCell>
                        <TableCell align="center">
                          <b>Box 2</b>
                        </TableCell>
                        <TableCell style={{ width: 10 }}></TableCell>
                        <TableCell align="center"><b>Box 3</b></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <InputField
                            type={InputType.TEXT}
                            label=""
                            name="txtPWDOffered"
                            key="txtPWDOffered"
                             disabled={override == true ? false : true}
                            defaultValue={model?.txtPWDOffered}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "txtPWDOffered",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.txtPWDOffered}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            InputProps={{
                              startAdornment: (
                                <React.Fragment>
                                  <div className={classes.leftLabel}>$</div>
                                </React.Fragment>
                              ),
                            }}
                            helperText={
                              data.errors.txtPWDOffered
                                ? (data.errors.txtPWDOffered as FieldError)
                                    .message
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <b>-</b>
                        </TableCell>
                        <TableCell>
                          <InputField
                            type={InputType.NUMBER}
                            label=""
                            name="txtPWDBox2Offered"
                            key="txtPWDBox2Offered"
                            decimal
                            thousandSeparator
                             disabled={override == true ? false : true}
                            defaultValue={model?.txtPWDBox2Offered}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange("txtPWDBox2Offered", v.target.value);
                            }}
                            error={!!data.errors.txtPWDBox2Offered}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            InputProps={{
                              startAdornment: (
                                <React.Fragment>
                                  <div className={classes.leftLabel}>$</div>
                                </React.Fragment>
                              ),
                            }}
                            helperText={
                              data.errors.txtPWDBox2Offered
                                ? (data.errors.txtPWDBox2Offered as FieldError).message
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <b>=</b>
                        </TableCell>
                        <TableCell>
                          <InputField
                            type={InputType.NUMBER}
                            label=""
                            name="txtPWDBox3"
                            key="txtPWDBox3"
                            decimal
                            thousandSeparator
                             disabled={override == true ? false : true}
                            defaultValue={model?.txtPWDBox3}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "txtPWDBox3",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.txtPWDBox3}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            InputProps={{
                              startAdornment: (
                                <React.Fragment>
                                  <div className={classes.leftLabel}>$</div>
                                </React.Fragment>
                              ),
                            }}
                            helperText={
                              data.errors.txtPWDBox3
                                ? (
                                    data.errors
                                      .txtPWDBox3 as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <b>IV. Allocation of Post-withdrawal Disbursement</b>
                  <div>
                    Because COD will only accept whole dollar entries for Direct
                    Loans, a Direct Loan disbursement will have to be rounded up
                    or down to the nearest whole dollar, not to exceed annual or
                    aggregate limits.
                  </div>
                  <div>
                    If an institution only awards Pell and Iraq and Afghanistan
                    Service Grants in whole dollars, the total award to the
                    student for the period must be rounded up or down to the
                    nearest whole dollar, not to exceed the annual or lifetime
                    eligibility limitations.
                  </div>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <b>Type of Aid</b>
                        </TableCell>
                        <TableCell>
                          <b>Loan Amount School Seeks to Credit to Account</b>
                        </TableCell>
                        <TableCell>
                          <b>Loan Amount Authorized to Credit to Account</b>
                        </TableCell>
                        <TableCell>
                          <b>Title IV Aid Credited to Account</b>
                        </TableCell>
                        <TableCell>
                          <b>Loan Amount Offered as Direct Disbursement</b>
                        </TableCell>
                        <TableCell>
                          <b>Loan Amount Accepted as Direct Disbursement</b>
                        </TableCell>
                        <TableCell>
                          <b>Title IV Disbursed Directly to Student</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Pell Grant</TableCell>
                        <TableCell>N/A</TableCell>
                        <TableCell>N/A</TableCell>
                        <TableCell>
                          <InputField
                            type={InputType.NUMBER}
                            label=""
                            name="pwdPell3"
                            key="pwdPell3"
                            decimal
                            thousandSeparator
                             disabled={override == true ? false : true}
                            defaultValue={model?.pwdPell3}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange("pwdPell3", v.target.value);
                            }}
                            error={!!data.errors.pwdPell3}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.pwdPell3
                                ? (data.errors.pwdPell3 as FieldError).message
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell>N/A</TableCell>
                        <TableCell>N/A</TableCell>
                        <TableCell>
                          <InputField
                            type={InputType.NUMBER}
                            label=""
                            name="pwdPell3"
                            key="pwdPell3"
                            decimal
                            thousandSeparator
                             disabled={override == true ? false : true}
                            defaultValue={model?.pwdPell3}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange("pwdPell3", v.target.value);
                            }}
                            error={!!data.errors.pwdPell3}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.pwdPell3
                                ? (data.errors.pwdPell3 as FieldError).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>FSEOG</TableCell>
                        <TableCell>N/A</TableCell>
                        <TableCell>N/A</TableCell>
                        <TableCell>
                          <InputField
                            type={InputType.NUMBER}
                            label=""
                            name="pwdFSEOG3"
                            key="pwdFSEOG3"
                            decimal
                            thousandSeparator
                             disabled={override == true ? false : true}
                            defaultValue={model?.pwdFSEOG3}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange("pwdFSEOG3", v.target.value);
                            }}
                            error={!!data.errors.pwdFSEOG3}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.pwdFSEOG3
                                ? (data.errors.pwdFSEOG3 as FieldError).message
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell>N/A</TableCell>
                        <TableCell>N/A</TableCell>
                        <TableCell>
                          <InputField
                            type={InputType.NUMBER}
                            label=""
                            name="pwdFSEOG6"
                            key="pwdFSEOG6"
                            decimal
                            thousandSeparator
                             disabled={override == true ? false : true}
                            defaultValue={model?.pwdFSEOG6}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange("pwdFSEOG6", v.target.value);
                            }}
                            error={!!data.errors.pwdFSEOG6}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.pwdFSEOG6
                                ? (data.errors.pwdFSEOG6 as FieldError).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>TEACH Grant</TableCell>
                        <TableCell>N/A</TableCell>
                        <TableCell>N/A</TableCell>
                        <TableCell>
                          <InputField
                            type={InputType.NUMBER}
                            label=""
                            name="pwdTeach3"
                            key="pwdTeach3"
                            decimal
                            thousandSeparator
                             disabled={override == true ? false : true}
                            defaultValue={model?.pwdTeach3}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange("pwdTeach3", v.target.value);
                            }}
                            error={!!data.errors.pwdTeach3}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.pwdTeach3
                                ? (data.errors.pwdTeach3 as FieldError).message
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell>N/A</TableCell>
                        <TableCell>N/A</TableCell>
                        <TableCell>
                          <InputField
                            type={InputType.NUMBER}
                            label=""
                            name="pwdTeach6"
                            key="pwdTeach6"
                            decimal
                            thousandSeparator
                             disabled={override == true ? false : true}
                            defaultValue={model?.pwdTeach6}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange("pwdTeach6", v.target.value);
                            }}
                            error={!!data.errors.pwdTeach6}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.pwdTeach6
                                ? (data.errors.pwdTeach6 as FieldError).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Iraq and Afganistan Service Grant</TableCell>
                        <TableCell>N/A</TableCell>
                        <TableCell>N/A</TableCell>
                        <TableCell>
                          <InputField
                            type={InputType.NUMBER}
                            label=""
                            name="pwdIASG3"
                            key="pwdIASG3"
                            decimal
                            thousandSeparator
                             disabled={override == true ? false : true}
                            defaultValue={model?.pwdIASG3}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange("pwdIASG3", v.target.value);
                            }}
                            error={!!data.errors.pwdIASG3}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.pwdIASG3
                                ? (data.errors.pwdIASG3 as FieldError).message
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell>N/A</TableCell>
                        <TableCell>N/A</TableCell>
                        <TableCell>
                          <InputField
                            type={InputType.NUMBER}
                            label=""
                            name="pwdIASG6"
                            key="pwdIASG6"
                            decimal
                            thousandSeparator
                             disabled={override == true ? false : true}
                            defaultValue={model?.pwdIASG6}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange("pwdIASG6", v.target.value);
                            }}
                            error={!!data.errors.pwdIASG6}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.pwdIASG6
                                ? (data.errors.pwdIASG6 as FieldError).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                    <TableRow>
                      <TableCell>Subsidized Direct loan</TableCell>
                      <TableCell>
                        <InputField
                          type={InputType.NUMBER}
                          label=""
                          name="pwdSub1"
                          key="pwdSub1"
                          decimal
                          thousandSeparator
                           disabled={override == true ? false : true}
                          defaultValue={model?.pwdSub1}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("pwdSub1", v.target.value);
                          }}
                          error={!!data.errors.pwdSub1}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          helperText={
                            data.errors.pwdSub1
                              ? (data.errors.pwdSub1 as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <InputField
                          type={InputType.NUMBER}
                          label=""
                          name="pwdSub2"
                          key="pwdSub2"
                          decimal
                          thousandSeparator
                           disabled={override == true ? false : true}
                          defaultValue={model?.pwdSub2}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("pwdSub2", v.target.value);
                          }}
                          error={!!data.errors.pwdSub2}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          helperText={
                            data.errors.pwdSub2
                              ? (data.errors.pwdSub2 as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <InputField
                          type={InputType.NUMBER}
                          label=""
                          name="pwdSub3"
                          key="pwdSub3"
                          decimal
                          thousandSeparator
                           disabled={override == true ? false : true}
                          defaultValue={model?.pwdSub3}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("pwdSub3", v.target.value);
                          }}
                          error={!!data.errors.pwdSub3}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          helperText={
                            data.errors.pwdSub3
                              ? (data.errors.pwdSub3 as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <InputField
                          type={InputType.NUMBER}
                          label=""
                          name="pwdSub4"
                          key="pwdSub4"
                          decimal
                          thousandSeparator
                           disabled={override == true ? false : true}
                          defaultValue={model?.pwdSub4}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("pwdSub4", v.target.value);
                          }}
                          error={!!data.errors.pwdSub4}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          helperText={
                            data.errors.pwdSub4
                              ? (data.errors.pwdSub4 as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <InputField
                          type={InputType.NUMBER}
                          label=""
                          name="pwdSub5"
                          key="pwdSub5"
                          decimal
                          thousandSeparator
                           disabled={override == true ? false : true}
                          defaultValue={model?.pwdSub5}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("pwdSub5", v.target.value);
                          }}
                          error={!!data.errors.pwdSub5}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          helperText={
                            data.errors.pwdSub5
                              ? (data.errors.pwdSub5 as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <InputField
                          type={InputType.NUMBER}
                          label=""
                          name="pwdSub6"
                          key="pwdSub6"
                          decimal
                          thousandSeparator
                           disabled={override == true ? false : true}
                          defaultValue={model?.pwdSub6}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("pwdSub6", v.target.value);
                          }}
                          error={!!data.errors.pwdSub6}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          helperText={
                            data.errors.pwdSub6
                              ? (data.errors.pwdSub6 as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Unsubsidized Direct loan</TableCell>
                      <TableCell>
                        <InputField
                          type={InputType.NUMBER}
                          label=""
                          name="pwdUnSub1"
                          key="pwdUnSub1"
                          decimal
                          thousandSeparator
                           disabled={override == true ? false : true}
                          defaultValue={model?.pwdUnSub1}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("pwdUnSub1", v.target.value);
                          }}
                          error={!!data.errors.pwdUnSub1}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          helperText={
                            data.errors.pwdUnSub1
                              ? (data.errors.pwdUnSub1 as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <InputField
                          type={InputType.NUMBER}
                          label=""
                          name="pwdUnSub2"
                          key="pwdUnSub2"
                          decimal
                          thousandSeparator
                           disabled={override == true ? false : true}
                          defaultValue={model?.pwdUnSub2}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("pwdUnSub2", v.target.value);
                          }}
                          error={!!data.errors.pwdUnSub2}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          helperText={
                            data.errors.pwdUnSub2
                              ? (data.errors.pwdUnSub2 as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <InputField
                          type={InputType.NUMBER}
                          label=""
                          name="pwdUnSub3"
                          key="pwdUnSub3"
                          decimal
                          thousandSeparator
                           disabled={override == true ? false : true}
                          defaultValue={model?.pwdUnSub3}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("pwdUnSub3", v.target.value);
                          }}
                          error={!!data.errors.pwdUnSub3}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          helperText={
                            data.errors.pwdUnSub3
                              ? (data.errors.pwdUnSub3 as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <InputField
                          type={InputType.NUMBER}
                          label=""
                          name="pwdUnSub4"
                          key="pwdUnSub4"
                          decimal
                          thousandSeparator
                           disabled={override == true ? false : true}
                          defaultValue={model?.pwdUnSub4}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("pwdUnSub4", v.target.value);
                          }}
                          error={!!data.errors.pwdUnSub4}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          helperText={
                            data.errors.pwdUnSub4
                              ? (data.errors.pwdUnSub4 as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <InputField
                          type={InputType.NUMBER}
                          label=""
                          name="pwdUnSub5"
                          key="pwdUnSub5"
                          decimal
                          thousandSeparator
                           disabled={override == true ? false : true}
                          defaultValue={model?.pwdUnSub5}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("pwdUnSub5", v.target.value);
                          }}
                          error={!!data.errors.pwdUnSub5}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          helperText={
                            data.errors.pwdUnSub5
                              ? (data.errors.pwdUnSub5 as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <InputField
                          type={InputType.NUMBER}
                          label=""
                          name="pwdUnSub6"
                          key="pwdUnSub6"
                          decimal
                          thousandSeparator
                           disabled={override == true ? false : true}
                          defaultValue={model?.pwdUnSub6}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("pwdUnSub6", v.target.value);
                          }}
                          error={!!data.errors.pwdUnSub6}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          helperText={
                            data.errors.pwdUnSub6
                              ? (data.errors.pwdUnSub6 as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Direct Graduate Plus loan</TableCell>
                      <TableCell>
                        <InputField
                          type={InputType.NUMBER}
                          label=""
                          name="pwdGrad1"
                          key="pwdGrad1"
                          decimal
                          thousandSeparator
                           disabled={override == true ? false : true}
                          defaultValue={model?.pwdGrad1}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("pwdGrad1", v.target.value);
                          }}
                          error={!!data.errors.pwdGrad1}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          helperText={
                            data.errors.pwdGrad1
                              ? (data.errors.pwdGrad1 as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <InputField
                          type={InputType.NUMBER}
                          label=""
                          name="pwdGrad2"
                          key="pwdGrad2"
                          decimal
                          thousandSeparator
                           disabled={override == true ? false : true}
                          defaultValue={model?.pwdGrad2}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("pwdGrad2", v.target.value);
                          }}
                          error={!!data.errors.pwdGrad2}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          helperText={
                            data.errors.pwdGrad2
                              ? (data.errors.pwdGrad2 as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <InputField
                          type={InputType.NUMBER}
                          label=""
                          name="pwdGrad3"
                          key="pwdGrad3"
                          decimal
                          thousandSeparator
                           disabled={override == true ? false : true}
                          defaultValue={model?.pwdGrad3}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("pwdGrad3", v.target.value);
                          }}
                          error={!!data.errors.pwdGrad3}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          helperText={
                            data.errors.pwdGrad3
                              ? (data.errors.pwdGrad3 as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <InputField
                          type={InputType.NUMBER}
                          label=""
                          name="pwdGrad4"
                          key="pwdGrad4"
                          decimal
                          thousandSeparator
                           disabled={override == true ? false : true}
                          defaultValue={model?.pwdGrad4}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("pwdGrad4", v.target.value);
                          }}
                          error={!!data.errors.pwdGrad4}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          helperText={
                            data.errors.pwdGrad4
                              ? (data.errors.pwdGrad4 as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <InputField
                          type={InputType.NUMBER}
                          label=""
                          name="pwdGrad5"
                          key="pwdGrad5"
                          decimal
                          thousandSeparator
                           disabled={override == true ? false : true}
                          defaultValue={model?.pwdGrad5}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("pwdGrad5", v.target.value);
                          }}
                          error={!!data.errors.pwdGrad5}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          helperText={
                            data.errors.pwdGrad5
                              ? (data.errors.pwdGrad5 as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <InputField
                          type={InputType.NUMBER}
                          label=""
                          name="pwdGrad6"
                          key="pwdGrad6"
                          decimal
                          thousandSeparator
                           disabled={override == true ? false : true}
                          defaultValue={model?.pwdGrad6}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("pwdGrad6", v.target.value);
                          }}
                          error={!!data.errors.pwdGrad6}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          helperText={
                            data.errors.pwdGrad6
                              ? (data.errors.pwdGrad6 as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Direct Parent Plus loan</TableCell>
                      <TableCell>
                        <InputField
                          type={InputType.NUMBER}
                          label=""
                          name="pwdParent1"
                          key="pwdParent1"
                          decimal
                          thousandSeparator
                           disabled={override == true ? false : true}
                          defaultValue={model?.pwdParent1}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("pwdParent1", v.target.value);
                          }}
                          error={!!data.errors.pwdParent1}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          helperText={
                            data.errors.pwdParent1
                              ? (data.errors.pwdParent1 as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <InputField
                          type={InputType.NUMBER}
                          label=""
                          name="pwdParent2"
                          key="pwdParent2"
                          decimal
                          thousandSeparator
                           disabled={override == true ? false : true}
                          defaultValue={model?.pwdParent2}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("pwdParent2", v.target.value);
                          }}
                          error={!!data.errors.pwdParent2}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          helperText={
                            data.errors.pwdParent2
                              ? (data.errors.pwdParent2 as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <InputField
                          type={InputType.NUMBER}
                          label=""
                          name="pwdParent3"
                          key="pwdParent3"
                          decimal
                          thousandSeparator
                           disabled={override == true ? false : true}
                          defaultValue={model?.pwdParent3}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("pwdParent3", v.target.value);
                          }}
                          error={!!data.errors.pwdParent3}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          helperText={
                            data.errors.pwdParent3
                              ? (data.errors.pwdParent3 as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <InputField
                          type={InputType.NUMBER}
                          label=""
                          name="pwdParent4"
                          key="pwdParent4"
                          decimal
                          thousandSeparator
                           disabled={override == true ? false : true}
                          defaultValue={model?.pwdParent4}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("pwdParent4", v.target.value);
                          }}
                          error={!!data.errors.pwdParent4}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          helperText={
                            data.errors.pwdParent4
                              ? (data.errors.pwdParent4 as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <InputField
                          type={InputType.NUMBER}
                          label=""
                          name="pwdParent5"
                          key="pwdParent5"
                          decimal
                          thousandSeparator
                           disabled={override == true ? false : true}
                          defaultValue={model?.pwdParent5}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("pwdParent5", v.target.value);
                          }}
                          error={!!data.errors.pwdParent5}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          helperText={
                            data.errors.pwdParent5
                              ? (data.errors.pwdParent5 as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <InputField
                          type={InputType.NUMBER}
                          label=""
                          name="pwdParent6"
                          key="pwdParent6"
                          decimal
                          thousandSeparator
                           disabled={override == true ? false : true}
                          defaultValue={model?.pwdParent6}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("pwdParent6", v.target.value);
                          }}
                          error={!!data.errors.pwdParent6}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          helperText={
                            data.errors.pwdParent6
                              ? (data.errors.pwdParent6 as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="right">
                        <b>Totals</b>
                      </TableCell>
                      <TableCell>
                        <InputField
                          type={InputType.NUMBER}
                          label=""
                          name="pwdTotal1"
                          key="pwdTotal1"
                          decimal
                          thousandSeparator
                           disabled={override == true ? false : true}
                          defaultValue={model?.pwdTotal1}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("pwdTotal1", v.target.value);
                          }}
                          error={!!data.errors.pwdTotal1}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          helperText={
                            data.errors.pwdTotal1
                              ? (data.errors.pwdTotal1 as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <InputField
                          type={InputType.NUMBER}
                          label=""
                          name="pwdTotal2"
                          key="pwdTotal2"
                          decimal
                          thousandSeparator
                           disabled={override == true ? false : true}
                          defaultValue={model?.pwdTotal2}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("pwdTotal2", v.target.value);
                          }}
                          error={!!data.errors.pwdTotal2}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          helperText={
                            data.errors.pwdTotal2
                              ? (data.errors.pwdTotal2 as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <InputField
                          type={InputType.NUMBER}
                          label=""
                          name="pwdTotal3"
                          key="pwdTotal3"
                          decimal
                          thousandSeparator
                           disabled={override == true ? false : true}
                          defaultValue={model?.pwdTotal3}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("pwdTotal3", v.target.value);
                          }}
                          error={!!data.errors.pwdTotal3}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          helperText={
                            data.errors.pwdTotal3
                              ? (data.errors.pwdTotal3 as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <InputField
                          type={InputType.NUMBER}
                          label=""
                          name="pwdTotal4"
                          key="pwdTotal4"
                          decimal
                          thousandSeparator
                           disabled={override == true ? false : true}
                          defaultValue={model?.pwdTotal4}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("pwdTotal4", v.target.value);
                          }}
                          error={!!data.errors.pwdTotal4}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          helperText={
                            data.errors.pwdTotal4
                              ? (data.errors.pwdTotal4 as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <InputField
                          type={InputType.NUMBER}
                          label=""
                          name="pwdTotal5"
                          key="pwdTotal5"
                          decimal
                          thousandSeparator
                           disabled={override == true ? false : true}
                          defaultValue={model?.pwdTotal5}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("pwdTotal5", v.target.value);
                          }}
                          error={!!data.errors.pwdTotal5}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          helperText={
                            data.errors.pwdTotal5
                              ? (data.errors.pwdTotal5 as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <InputField
                          type={InputType.NUMBER}
                          label=""
                          name="pwdTotal6"
                          key="pwdTotal6"
                          decimal
                          thousandSeparator
                           disabled={override == true ? false : true}
                          defaultValue={model?.pwdTotal6}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("pwdTotal6", v.target.value);
                          }}
                          error={!!data.errors.pwdTotal6}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          helperText={
                            data.errors.pwdTotal6
                              ? (data.errors.pwdTotal6 as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </Table>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <b>V. Authorizations and Notifications</b>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <br />
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          Post-withdrawal disbursement loan notification sent to
                          student and/or parent on
                        </TableCell>
                        <TableCell>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              disableToolbar
                              variant="inline"
                              format="MM/dd/yyyy"
                              id="dtPostWithdrwal"
                              name="dtPostWithdrwal"
                              label="MM/DD/YYYY"
                              value={
                                model?.dtPostWithdrwal ? model.dtPostWithdrwal : null
                              }
                              onChange={(e: any, value: any) =>
                                handleFieldOnChange(
                                  "dtPostWithdrwal",
                                  value ? value : undefined
                                )
                              }
                              className={classes.startDateField}
                              KeyboardButtonProps={{
                                "aria-label": "report date",
                              }}
                              error={!!data.errors["dtPostWithdrwal"]}
                              innerRef={(ref: any) => {
                                data.register("dtPostWithdrwal", {
                                  required: true,
                                });
                                data.setValue(
                                  "dtPostWithdrwal",
                                  model?.dtDeadline
                                );
                              }}
                              helperText={
                                data.errors["dtPostWithdrwal"]
                                  ? (
                                      data.errors[
                                        "dtPostWithdrwal"
                                      ] as FieldError
                                    ).message
                                  : undefined
                              }
                            />
                          </MuiPickersUtilsProvider>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <br />
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          Deadline for student and/or parent to respond
                        </TableCell>
                        <TableCell>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              disableToolbar
                              variant="inline"
                              format="MM/dd/yyyy"
                              id="dtDeadline"
                              name="dtDeadline"
                              label=""
                              value={
                                model?.dtDeadline ? model.dtDeadline : null
                              }
                              onChange={(e: any, value: any) =>
                                handleFieldOnChange(
                                  "dtDeadline",
                                  value ? value : undefined
                                )
                              }
                              className={classes.startDateField}
                              KeyboardButtonProps={{
                                "aria-label": "report date",
                              }}
                              error={!!data.errors["dtDeadline"]}
                              innerRef={(ref: any) => {
                                data.register("dtDeadline", {
                                  required: true,
                                });
                                data.setValue(
                                  "dtDeadline",
                                  model?.dtDeadline
                                );
                              }}
                              helperText={
                                data.errors["dtDeadline"]
                                  ? (
                                      data.errors[
                                        "dtDeadline"
                                      ] as FieldError
                                    ).message
                                  : undefined
                              }
                            />
                          </MuiPickersUtilsProvider>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <br />
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <FormControlLabel
                            key="chkResponseReceived"
                            control={
                              <Checkbox
                                checked={model?.chkResponseReceived}
                                color="primary"
                                onChange={(e) =>
                                  handleFieldOnChange(
                                    "chkResponseReceived",
                                    e.target.checked
                                  )
                                }
                              />
                            }
                            label="Response received from Student and/or parent on"
                          />
                        </TableCell>
                        <TableCell>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              disableToolbar
                              variant="inline"
                              format="MM/dd/yyyy"
                              id="dtResponseReceived"
                              name="dtResponseReceived"
                              label=""
                              value={
                                model?.dtDeadline ? model.dtDeadline : null
                              }
                              onChange={(e: any, value: any) =>
                                handleFieldOnChange(
                                  "dtResponseReceived",
                                  value ? value : undefined
                                )
                              }
                              className={classes.startDateField}
                              KeyboardButtonProps={{
                                "aria-label": "report date",
                              }}
                              error={!!data.errors["dtResponseReceived"]}
                              innerRef={(ref: any) => {
                                data.register("dtResponseReceived", {
                                  required: true,
                                });
                                data.setValue(
                                  "dtResponseReceived",
                                  model?.dtDeadline
                                );
                              }}
                              helperText={
                                data.errors["dtResponseReceived"]
                                  ? (
                                      data.errors[
                                        "dtResponseReceived"
                                      ] as FieldError
                                    ).message
                                  : undefined
                              }
                            />
                          </MuiPickersUtilsProvider>
                        </TableCell>
                        <TableCell>
                          <FormControlLabel
                            key="chkResponseNotReceived"
                            control={
                              <Checkbox
                                checked={model?.chkResponseNotReceived}
                                color="primary"
                                onChange={(e) =>
                                  handleFieldOnChange(
                                    "chkResponseNotReceived",
                                    e.target.checked
                                  )
                                }
                              />
                            }
                            label="Response not received"
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <br />
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <FormControlLabel
                            key="chkNotAccept"
                            control={
                              <Checkbox
                                checked={model?.chkNotAccept}
                                color="primary"
                                onChange={(e) =>
                                  handleFieldOnChange(
                                    "chkNotAccept",
                                    e.target.checked
                                  )
                                }
                              />
                            }
                            label="School does not accept late response"
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <b>VI. Date Funds Sent</b>
                  <br />
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          Date Direct Disbursement mailed or transferred
                        </TableCell>
                        <TableCell>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              disableToolbar
                              variant="inline"
                              format="MM/dd/yyyy"
                              id="dtGrantTransferred"
                              name="dtGrantTransferred"
                              label="MM/DD/YYYY"
                              value={
                                model?.dtDeadline ? model.dtDeadline : null
                              }
                              onChange={(e: any, value: any) =>
                                handleFieldOnChange(
                                  "dtGrantTransferred",
                                  value ? value : undefined
                                )
                              }
                              className={classes.startDateField}
                              KeyboardButtonProps={{
                                "aria-label": "report date",
                              }}
                              error={!!data.errors["dtGrantTransferred"]}
                              innerRef={(ref: any) => {
                                data.register("dtGrantTransferred", {
                                  required: true,
                                });
                                data.setValue(
                                  "dtGrantTransferred",
                                  model?.dtDeadline
                                );
                              }}
                              helperText={
                                data.errors["dtGrantTransferred"]
                                  ? (
                                      data.errors[
                                        "dtGrantTransferred"
                                      ] as FieldError
                                    ).message
                                  : undefined
                              }
                            />
                          </MuiPickersUtilsProvider>
                        </TableCell>
                        <TableCell>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              disableToolbar
                              variant="inline"
                              format="MM/dd/yyyy"
                              id="dtLoanTransferred"
                              name="dtLoanTransferred"
                              label="MM/DD/YYYY"
                              value={
                                model?.dtDeadline ? model.dtDeadline : null
                              }
                              onChange={(e: any, value: any) =>
                                handleFieldOnChange(
                                  "dtLoanTransferred",
                                  value ? value : undefined
                                )
                              }
                              className={classes.startDateField}
                              KeyboardButtonProps={{
                                "aria-label": "report date",
                              }}
                              error={!!data.errors["dtLoanTransferred"]}
                              innerRef={(ref: any) => {
                                data.register("dtLoanTransferred", {
                                  required: true,
                                });
                                data.setValue(
                                  "dtLoanTransferred",
                                  model?.dtDeadline
                                );
                              }}
                              helperText={
                                data.errors["dtLoanTransferred"]
                                  ? (
                                      data.errors[
                                        "dtLoanTransferred"
                                      ] as FieldError
                                    ).message
                                  : undefined
                              }
                            />
                          </MuiPickersUtilsProvider>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </React.Fragment>
  );
};

export default ResultsTab11;
