import React from "react";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {
  Button,
  Card,
  CardContent,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import ChargeDefinitionVersions from "./../chargeDefinition/ChargeDefinitionVersions";
import TextField from "@material-ui/core/TextField";
import StatusAutoComplete from "../../../components/AutoComplete/StatusAutoComplete";
import IncrementTypeAutoComplete from "../../../../src/components/AutoComplete/IncrementTypeAutoComplete";
import { ChargeDefinition } from "../../../interfaces/setup/chargeDefinition/ChargeDefinition";
import CustomCardTitle from "../../../interfaces/common/card/CustomCardTitle";
import ProgressSaveButton from "../../../components/_Layout/Buttons/ProgressSaveButton";
import UndefinedChargeProgramsAutoComplete from "../../../components/AutoComplete/UndefinedChargeProgramsAutoComplete";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardTitle: {
      fontSize: 17,
      color: "Black",
      textDecoration: "bold",
      backgroundColor: theme.palette.secondary.dark,
      padding: theme.spacing(1, 1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardSeparator: {
      marginBottom: theme.spacing(1),
    },
    buttonColorPrimary: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    buttonColorTertiary: {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.error.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.error.main,
      },
      textDecoration: "bold",
      padding: theme.spacing(1),
      whiteSpace: "nowrap",
      margin: theme.spacing(1),
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
  })
);

type IChargeDefinitionDetailModelProps = {
  isCreatingNew: boolean;
  chargingDefinitionId: any;
  closeChargingDefinition: any;
  saveChargingDefinition: any;
  newChargingDefinition: any;
  model: ChargeDefinition;
  setModel: any;
  setLoading: any;
  loading: boolean;
  setSnackBarProps: any;
};

function ChargeDefinitionDetail(props: IChargeDefinitionDetailModelProps) {
  const classes = useStyles();
  const data = useFormContext();
  const selectedStudent = useSelector((state: any) => state.student);

  const [chargingDefinitionId, setchargeDefinitionIdInChild] = React.useState<any>(null);

  React.useEffect(() => {
    setchargeDefinitionIdInChild(props.chargingDefinitionId);
  }, [props.chargingDefinitionId]);

  const handelSaveDefinition = () => {
    if (!props.loading) {
      props.saveChargingDefinition();
    }
  };
  const closeChargingDefinition = () => {
    props.closeChargingDefinition();
  };

  const addNew = () => {
    setchargeDefinitionIdInChild(null);
    props.newChargingDefinition();
  };

  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  const ThemeTextField = withStyles((theme: any) =>
    createStyles({
      root: {
        "& .MuiFormLabel-root": {
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          width: "80%",
        },
      },
    })
  )(TextField);

  const setPrograms = (programs: any) => {
    let newProgram: string[] = [];
    if (programs !== null) {
      if (Array.isArray(programs)) {
        newProgram = programs.map((gr: any) => gr.value);
      } else {
        newProgram.push(programs.value);
      }
    }
    return newProgram;
  };

  const handleFieldOnChange = (
    fieldId: string,
    value: any,
    mapFunction?: Function
  ) => {
    let updatedModel = props.model;
    let newValue = mapFunction ? mapFunction(value) : value;
    (updatedModel as any)[fieldId] = newValue;
    props.setModel(updatedModel);
  };

  return (
    <Card>
      <CustomCardTitle title={"Charge Definition Details"} />
      <CardContent>
        <Grid container direction="row" spacing={2}>
          <Grid item md={4} sm={6} xs={12}>
            <ThemeTextField
              id="chargeDefinitionDescription"
              name="chargeDefinitionDescription"
              className={classes.textField}
              label="Charge Definition *"
              defaultValue={props?.model.chargeDefinitionDescription ?? ""}
              onChange={(v: any) => {
                handleFieldOnChange(
                  "chargeDefinitionDescription",
                  v ? v.target.value : undefined
                );
              }}
              error={!!data.errors.chargeDefinitionDescription}
              inputRef={data.register({ required: true })}
              helperText={
                data.errors.chargeDefinitionDescription
                  ? "Charge definition is required."
                  : undefined
              }
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <StatusAutoComplete
              id="statusId"
              name="statusId"
              label="Status *"
              valueFilter={
                props?.model.statusId
                  ? {
                    key: "value",
                    values: [props?.model.statusId],
                  }
                  : undefined
              }
              filterHandle={(v: any) => {
                handleFieldOnChange("statusId", v ? v.value : null);
              }}
              error={!!data.errors.statusId}
              inputRef={data.register({ required: true })}
              helperText={
                data.errors.statusId ? "Status is required." : undefined
              }
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <ThemeTextField
              id="campus"
              name="campus"
              className={classes.textField}
              label="Campus"
              value={selectedStudent?.campus}
              disabled={true}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <IncrementTypeAutoComplete
              id="incrementTypeId"
              name="incrementTypeId"
              label="Increment Type *"
              valueFilter={
                props?.model.incrementTypeId
                  ? {
                    key: "value",
                    values: [props?.model.incrementTypeId],
                  }
                  : undefined
              }
              error={!!data.errors.incrementTypeId}
              inputRef={data.register({
                required: true,
              })}
              helperText={
                data.errors.incrementTypeId
                  ? "Increment type is required"
                  : null
              }
              filterHandle={(v: any) => {
                handleFieldOnChange("incrementTypeId", v ? v.value : undefined);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <UndefinedChargeProgramsAutoComplete
              id="chargeDefinitionProgramVersions"
              name="chargeDefinitionProgramVersions"
              label="Charge Definition Program Versions *"
              includeCreditProgramVersions={true}
              params={{ fullWidth: true }}
              multiple={true}
              valueFilter={
                props?.model?.chargeDefinitionProgramVersions
                  ? {
                    key: "value",
                    values: props?.model?.chargeDefinitionProgramVersions,
                  }
                  : undefined
              }
              campusId={userSelectedCampus}
              filterHandle={(v: any) => {
                handleFieldOnChange(
                  "chargeDefinitionProgramVersions",
                  v || [],
                  setPrograms
                );
              }}
              error={!!data.errors.chargeDefinitionProgramVersions}
              inputRef={data.register({
                validate: {
                  atLeastOneRequired: (value) =>
                    (props?.model?.chargeDefinitionProgramVersions &&
                      props?.model?.chargeDefinitionProgramVersions.length > 0) ||
                    "At least one program version is required.",
                },
              })}
              helperText={
                data.errors.chargeDefinitionProgramVersions
                  ? "At least one program version is required."
                  : undefined
              }
              chargeDefinitionId={chargingDefinitionId == null ? chargingDefinitionId : props.chargingDefinitionId}
            />
          </Grid>
          <Grid item xs={12}>
            <ChargeDefinitionVersions
              model={props?.model}
              setModel={props?.setModel}
              isCreatingNew={props.isCreatingNew}
              setSnackBarProps={props.setSnackBarProps}
              chargingDefinitionId={props.chargingDefinitionId}
            ></ChargeDefinitionVersions>
          </Grid>

          <Grid item xs={12}>
            <ProgressSaveButton
              text="Save"
              onClick={handelSaveDefinition}
              loading={props.loading}
              size="small"
            ></ProgressSaveButton>
            <Button
              style={{ marginLeft: 5 }}
              onClick={addNew}
              color="primary"
              variant="contained"
              size="small"
              type="button"
            >
              Add New
            </Button>
            <Button
              style={{ marginLeft: 5 }}
              onClick={closeChargingDefinition}
              color="secondary"
              variant="contained"
              size="small"
              type="button"
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
export default ChargeDefinitionDetail;
