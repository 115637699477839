import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Card,
  CardContent,
} from "@material-ui/core";
import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
/* import { ChargeDefinitionVersion } from "../../../interfaces/setup/chargeDefinition/ChargeDefinitionVersion"; */
import { ChargeDefinitionVersion } from "../../../interfaces/setup/chargeDefinition/ChargeDefinitionVersion";

import {
  IChargingMethodIncrement,
  CharginMethodIncrement,
} from "../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/CharginMethod/IChargingMethodIncrement";
import IncrementDetailsCard from "./IncrementDetailsCard";
import CustomCardTitle from "../../../interfaces/common/card/CustomCardTitle";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardTitle: {
      fontSize: 17,
      color: "Black",
      textDecoration: "bold",
      backgroundColor: theme.palette.secondary.dark,
      padding: theme.spacing(1, 1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardSeparator: {
      marginBottom: theme.spacing(1),
    },
    buttonColorPrimary: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
      textDecoration: "bold",
      padding: theme.spacing(1),
      whiteSpace: "nowrap",
      margin: theme.spacing(2, 0),
    },
    buttonColorTertiary: {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.error.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.error.main,
      },
      textDecoration: "bold",
      padding: theme.spacing(1),
      whiteSpace: "nowrap",
      margin: theme.spacing(1),
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
  })
);

type IChargeDefinitionVersionDetailModelProps = {
  isCreatingNew: boolean;
  closeChargingDefinition: any;
  model: ChargeDefinitionVersion;
  setModel: any;
  methods: any;
  readonly?: boolean;
  chargingDefinitionId?: any;
};

function ChargeDefinitionVersionDetail(
  props: IChargeDefinitionVersionDetailModelProps
) {
  const classes = useStyles();

  return (
    <Card>
      <CustomCardTitle title={"Charge Version Details"} />
      <CardContent>
        <IncrementDetailsCard
          model={props.model}
          setModel={props.setModel}
          isEditable={true}
          usesActualHours={true}
          methods={props.methods}
          readonly={props.readonly}
          chargingDefinitionId={props.chargingDefinitionId}
        />
      </CardContent>
    </Card>
  );
}
export default ChargeDefinitionVersionDetail;
