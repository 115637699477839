import React, { useState, useEffect } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { FormContext, useForm, FieldError } from "react-hook-form";
import {
  IEvaluatedPolicies,
  IEvaluatedPolicy,
  ICharges,
} from "../../../interfaces/student/termination/IStudentTermination";
import * as terminationApi from "../../../api/student/termination/studentTerminationApi";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      minHeight: "100%",
      paddingTop: theme.spacing(3),
      overflowX: "hidden",
      overflowY: "auto",
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
    startDateField: {
      width: "100%",
    },
    leftLabel: {
      width: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: "black",
      color: "white",
      textAlign: "center",
      marginRight: theme.spacing(1),
      display: "inline-block",
    },
    cellColor: {
      backgroundColor: "black",
      color: "white",
      width: "200px",
    }
  })
);

interface IRefundProps {
  terminationId: string;
  page: string;
  pageKey: number;
  showLoading: any;
  hideLoading: any;
  showFutureChargesMessage: boolean;
  setConfirmationDialog: (params: any) => void;
}

const RefundPolicy = (props: IRefundProps) => {
  const classes = useStyles({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [terminationId, setTerminationId] = React.useState<string>("");
  const [evaluatedPolicy, setEvaluatedPolicy] =
    React.useState<IEvaluatedPolicies>();
  const data = useForm<any>({ mode: "onBlur" });

  useEffect(() => {
    setTerminationId(props.terminationId);
  }, [props]);

  useEffect(() => {
    if(terminationId != "")
    {
      terminationApi.IsAnyChargePosted(terminationId).then(
        (response: any) => {
          if (response && response == true) {
            GetEvaluateRefundPolicies(props.terminationId);       
          }
          else if(props.showFutureChargesMessage == true)
          {
            props.setConfirmationDialog({
              open: true,
              title: "Add future charges",
              confirmOnly: true,
              message: "Charges for the full program length are required to evaluate the refund policies. Please add future charges for the program using 'Add future charges' option.",
            });
          }
          props.hideLoading();
        },
        (exception: any) => {
          props.hideLoading();
        }
      );      
    }    
  }, [terminationId]);

  const GetEvaluateRefundPolicies = (terminationId: string) => {
    props.showLoading();
    terminationApi.EvaluateRefundPolicies(terminationId).then(
      (response: any) => {
        if (response && response.result) {
          setEvaluatedPolicy(response.result);          
        }
        props.hideLoading();
      },
      (exception: any) => {
        props.hideLoading();
      }
    );
  };

  const isApplied = (percentage: any) =>
    percentage && percentage > 0 && percentage < 100;

  const getCurrencyFormattedValue = (
    value: any,
    addBracesForNegativeNumbers = true
  ) => {
    let numberVal = parseFloat(value?.toString().trim() || "0.00");

    const isNegative = addBracesForNegativeNumbers && numberVal < 0;
    if (isNegative) {
      numberVal = Math.abs(numberVal);
    }

    return `${isNegative ? "(" : ""}$${formatNumberAsCurrency(numberVal)}${
      isNegative ? ")" : ""
    }`;
  };
  const formatNumberAsCurrency = (num: any) =>
    num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");


  return (
    <React.Fragment>
      <Box>
        {evaluatedPolicy?.evaluatedPolicies.map((p: IEvaluatedPolicy) => (
          <Box>
            <div>Refund calculation as per {p.refundPolicyDescription}</div>
            <div>
              {p.isChosenPolicy == true
                ? "THIS REFUND POLICY IS THE ONE MOST BENEFICIAL TO THE STUDENT. THEREFORE, IT IS THE ONE USED."
                : ""}
            </div>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Policy details:</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Refund period:</TableCell>
                  <TableCell>{p.refundPeriodDescription}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Rule applicable to student:</TableCell>
                  <TableCell>{p.ruleNumberApplicableToStudent}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Withdrawal period:</TableCell>
                  <TableCell>{p.withdrawalPeriod}</TableCell>
                </TableRow>
                {p.programCompletionRequiredByRuleAppliction &&
                p.programCompletionRequiredByRuleAppliction != "" ? (
                  <TableRow>
                    <TableCell>
                      Program/Period completion (if applicable as per rule):
                    </TableCell>
                    <TableCell>
                      {p.programCompletionRequiredByRuleAppliction}
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
                {p.calculatedProgramCompletionForStudent &&
                p.calculatedProgramCompletionForStudent != "" ? (
                  <TableRow>
                    <TableCell>
                      Program/Period completion calculated for the student (if
                      applicable as per rule):
                    </TableCell>
                    <TableCell>
                      {p.calculatedProgramCompletionForStudent}
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
                <TableRow>
                  <TableCell>Rule applicable reason:</TableCell>
                  <TableCell>{p.ruleApplicableMessage}</TableCell>
                </TableRow>
                {!p.isAllPoliciesAreByProgramVersion ? (
                  <React.Fragment>
                    <TableRow>
                      <TableCell>Amount earned as per rule:</TableCell>
                      <TableCell>{p.earnedCalculationMessage}</TableCell>
                    </TableRow>
                    {props.page == "termination" ? (
                      <TableRow>
                        <TableCell>Total contracted charges:</TableCell>
                        <TableCell>
                          {getCurrencyFormattedValue(p.totalPostedCharges)}
                        </TableCell>
                      </TableRow>
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                ) : (
                  ""
                )}
              </TableBody>
            </Table>
            {props.page == "institutional" ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.cellColor}>
                      Category
                    </TableCell>
                    <TableCell className={classes.cellColor}>
                      Posted charges
                    </TableCell>
                    <TableCell className={classes.cellColor}>
                      Charges earned for the period completed
                    </TableCell>
                    <TableCell className={classes.cellColor}>
                      Charges earned for the last incomplete period
                    </TableCell>
                    <TableCell className={classes.cellColor}>
                      Cumulative charges earned
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {p?.charges.map((c: ICharges) => (
                    <TableRow>
                      <TableCell>{c.category}</TableCell>
                      <TableCell>
                        {getCurrencyFormattedValue(c.postedCharges)}
                      </TableCell>
                      <TableCell>
                        {getCurrencyFormattedValue(
                          c.chargesEarnedForCompletedPeriods
                        )}
                      </TableCell>
                      <TableCell>
                        {getCurrencyFormattedValue(
                          c.chargesEarnedForLastPeriod
                        )}
                      </TableCell>
                      <TableCell>
                        {getCurrencyFormattedValue(c.cumulativeChargesEarned)}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={4}>Total earned</TableCell>
                    <TableCell>
                      {getCurrencyFormattedValue(
                        p.totalChargesEarnedAndTerminationFee
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            ) : (
              ""
            )}
            {!p.isAllPoliciesAreByProgramVersion ? (
              <React.Fragment>
                <div>Determining the refund amount</div>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.cellColor}>
                        Current Balance
                      </TableCell>
                      <TableCell>
                        {getCurrencyFormattedValue(p.currentBalance)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.cellColor}>
                        Add Return to Title IV
                      </TableCell>
                      <TableCell>
                        + {getCurrencyFormattedValue(p.returnToTitleIV)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.cellColor}>
                        Post Withdrawal Disbursement{" "}
                      </TableCell>
                      <TableCell>
                        -{" "}
                        {getCurrencyFormattedValue(
                          p.postWithdrawalDisbursement
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.cellColor}>
                        Unearned Charges
                      </TableCell>
                      <TableCell>
                        - {getCurrencyFormattedValue(p.unearnedCharges)}
                      </TableCell>
                    </TableRow>
                    {p.doesSchoolRetainsAnyPercentOfUnearnedCharges == true ? (
                      <TableRow>
                        <TableCell className={classes.cellColor}>
                          {p.percentOfUnearnedCharges + "% of unearned tuition"}
                        </TableCell>
                        <TableCell>
                          +{" "}
                          {getCurrencyFormattedValue(
                            p.chargesEarnedForNextPeriods
                          )}
                        </TableCell>
                      </TableRow>
                    ) : (
                      ""
                    )}
                    <TableRow>
                      <TableCell className={classes.cellColor}>
                        Termination Fee
                      </TableCell>
                      <TableCell>
                        + {getCurrencyFormattedValue(p.terminationFee)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.cellColor}>
                        New Student Balance
                      </TableCell>
                      <TableCell>
                        {getCurrencyFormattedValue(p.newStudentBalance)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </React.Fragment>
            ) : (
              ""
            )}

            {p.isAllPoliciesAreByProgramVersion ? (
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.cellColor}>
                      Tuition school may retain
                    </TableCell>
                    <TableCell>
                      {getCurrencyFormattedValue(p.totalTuitionChargesEarned)}
                    </TableCell>
                  </TableRow>
                  {p.doesSchoolRetainsAnyPercentOfUnearnedCharges == true ? (
                    <TableRow>
                      <TableCell className={classes.cellColor}>
                        {p.percentOfUnearnedCharges + "% of unearned tuition"}
                      </TableCell>
                      <TableCell>
                        {getCurrencyFormattedValue(
                          p.chargesEarnedForNextPeriods
                        )}
                      </TableCell>
                    </TableRow>
                  ) : (
                    ""
                  )}
                  <TableRow>
                    <TableCell className={classes.cellColor}>
                      Registration and/or Application Fee
                    </TableCell>
                    <TableCell>
                      {getCurrencyFormattedValue(
                        p.totalRegistrationAndApplicationFeeChargesEarned
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellColor}>
                      Kit and Books
                    </TableCell>
                    <TableCell>
                      {getCurrencyFormattedValue(
                        p.totalKitsAndBooksChargesEarned
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellColor}>
                      Sales Tax
                    </TableCell>
                    <TableCell>
                      {getCurrencyFormattedValue(p.totalSalesTaxChargesEarned)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellColor}>
                      Withdrawal and/or reentry Fee
                    </TableCell>
                    <TableCell>
                      {getCurrencyFormattedValue(p.terminationFee)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellColor}>
                      Other Fees
                    </TableCell>
                    <TableCell>
                      {getCurrencyFormattedValue(p.totalOtherFeesChargesEarned)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellColor}>
                      Total charges due to school
                    </TableCell>
                    <TableCell>
                      {getCurrencyFormattedValue(
                        p.totalChargesEarnedAndTerminationFee
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellColor}>
                      Payments by Student
                    </TableCell>
                    <TableCell>
                      {getCurrencyFormattedValue(p.paymentsByStudent)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellColor}>
                      Payments Financial Aid
                    </TableCell>
                    <TableCell>
                      {getCurrencyFormattedValue(p.paymentsFinancialAid)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellColor}>
                      Payments VA and/or Scholarship
                    </TableCell>
                    <TableCell>
                      {getCurrencyFormattedValue(p.paymentsVAandScholarship)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellColor}>
                      Less: refunds to student
                    </TableCell>
                    <TableCell>
                      {getCurrencyFormattedValue(p.refundsToStudent)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellColor}>
                      Less: refunds to Dept of Education
                    </TableCell>
                    <TableCell>
                      {getCurrencyFormattedValue(p.refundsToDeptOfEducation)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellColor}>PWD</TableCell>
                    <TableCell>
                      {getCurrencyFormattedValue(p.postWithdrawalDisbursement)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellColor}>
                      Total Payments and Refunds
                    </TableCell>
                    <TableCell>
                      {getCurrencyFormattedValue(p.totalPaymentsAndRefunds)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellColor}>
                      Total due to School
                    </TableCell>
                    <TableCell>
                      {getCurrencyFormattedValue(p.totalDueToSchool)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellColor}>
                      Refund to student
                    </TableCell>
                    <TableCell>
                      {getCurrencyFormattedValue(p.totalRefundToStudent)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellColor}>
                      Refund Due: Title IV Grants
                    </TableCell>
                    <TableCell>
                      {getCurrencyFormattedValue(p.titleIVGrants)}
                    </TableCell>
                  </TableRow>
                  {p.pellGrantSchoolReturn > 0 && (
                    <TableRow>
                      <TableCell className={classes.cellColor}>
                        Pell Grant
                      </TableCell>
                      <TableCell>
                        {getCurrencyFormattedValue(p.pellGrantSchoolReturn)}
                      </TableCell>
                    </TableRow>
                  )}
                  {p.fseogSchoolReturn > 0 && (
                    <TableRow>
                      <TableCell className={classes.cellColor}>FSEOG</TableCell>
                      <TableCell>
                        {getCurrencyFormattedValue(p.fseogSchoolReturn)}
                      </TableCell>
                    </TableRow>
                  )}
                  {p.teachGrantSchoolReturn > 0 && (
                    <TableRow>
                      <TableCell className={classes.cellColor}>
                        TEACH Grant
                      </TableCell>
                      <TableCell>
                        {getCurrencyFormattedValue(p.teachGrantSchoolReturn)}
                      </TableCell>
                    </TableRow>
                  )}
                  {p.iraqAfgGrantSchoolReturn > 0 && (
                    <TableRow>
                      <TableCell className={classes.cellColor}>
                        Iraq Afghanistan Service Grant
                      </TableCell>
                      <TableCell>
                        {getCurrencyFormattedValue(p.iraqAfgGrantSchoolReturn)}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell className={classes.cellColor}>
                      Refund Due: Title IV Loans
                    </TableCell>
                    <TableCell>
                      {getCurrencyFormattedValue(p.titleIVLoans)}
                    </TableCell>
                  </TableRow>
                  {p.unsubDirectLoanSchoolReturn > 0 && (
                    <TableRow>
                      <TableCell className={classes.cellColor}>
                        Unsubsidized Direct Loan
                      </TableCell>
                      <TableCell>
                        {getCurrencyFormattedValue(
                          p.unsubDirectLoanSchoolReturn
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                  {p.subDirectLoanSchoolReturn > 0 && (
                    <TableRow>
                      <TableCell className={classes.cellColor}>
                        Subsidized Direct Loan
                      </TableCell>
                      <TableCell>
                        {getCurrencyFormattedValue(p.subDirectLoanSchoolReturn)}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            ) : (
              ""
            )}

            <Table className="table-bordered policy-table">
              <TableBody>
                {isApplied(p.tuitionChargesEarnedPercentage) && (
                  <>
                    <TableRow>
                      <TableCell colSpan={2} className="policy-header-col">
                        Amount of tuition school can retain
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Total tuition on Contract</TableCell>
                      <TableCell>
                        {getCurrencyFormattedValue(p.totalTuitionChargesPosted)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>% tuition school can retain</TableCell>
                      <TableCell>{p.tuitionChargesEarnedPercentage}%</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Total tuition school may retain</TableCell>
                      <TableCell>
                        {getCurrencyFormattedValue(p.totalTuitionChargesEarned)}
                      </TableCell>
                    </TableRow>
                  </>
                )}
                {isApplied(
                  p.registrationAndApplicationFeeChargesEarnedPercentage
                ) && (
                  <>
                    <TableRow>
                      <TableCell colSpan={2} className="policy-header-col">
                        Amount of registration and/or application Fee school can
                        retain
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Total registration and/or application Fee on Contract
                      </TableCell>
                      <TableCell>
                        {getCurrencyFormattedValue(
                          p.totalRegistrationAndApplicationFeeChargesPosted
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        % registration and/or application Fee school can retain
                      </TableCell>
                      <TableCell>
                        {p.registrationAndApplicationFeeChargesEarnedPercentage}
                        %
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Total registration and/or application Fee school may
                        retain
                      </TableCell>
                      <TableCell>
                        {getCurrencyFormattedValue(
                          p.totalRegistrationAndApplicationFeeChargesEarned
                        )}
                      </TableCell>
                    </TableRow>
                  </>
                )}
                {isApplied(p.kitsAndBooksChargesEarnedPercentage) && (
                  <>
                    <TableRow>
                      <TableCell colSpan={2} className="policy-header-col">
                        Amount of kit and books school can retain
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Total kit and books on Contract</TableCell>
                      <TableCell>
                        {getCurrencyFormattedValue(
                          p.totalKitsAndBooksChargesPosted
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>% kit and books school can retain</TableCell>
                      <TableCell>
                        {p.kitsAndBooksChargesEarnedPercentage}%
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Total kit and books school may retain
                      </TableCell>
                      <TableCell>
                        {getCurrencyFormattedValue(
                          p.totalKitsAndBooksChargesEarned
                        )}
                      </TableCell>
                    </TableRow>
                  </>
                )}
                {isApplied(p.salesTaxChargesEarnedPercentage) && (
                  <>
                    <TableRow>
                      <TableCell colSpan={2} className="policy-header-col">
                        Amount of sales tax school can retain
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Total sales tax on Contract</TableCell>
                      <TableCell>
                        {getCurrencyFormattedValue(
                          p.totalSalesTaxChargesPosted
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>% sales tax school can retain</TableCell>
                      <TableCell>
                        {p.salesTaxChargesEarnedPercentage}%
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Total sales tax school may retain</TableCell>
                      <TableCell>
                        {getCurrencyFormattedValue(
                          p.totalSalesTaxChargesEarned
                        )}
                      </TableCell>
                    </TableRow>
                  </>
                )}
                {isApplied(p.otherFeesChargesEarnedPercentage) && (
                  <>
                    <TableRow>
                      <TableCell colSpan={2} className="policy-header-col">
                        Amount of other fees school can retain
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Total other fees on Contract</TableCell>
                      <TableCell>
                        {getCurrencyFormattedValue(
                          p.totalOtherFeesChargesPosted
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>% other fees school can retain</TableCell>
                      <TableCell>
                        {p.otherFeesChargesEarnedPercentage}%
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Total other fees school may retain</TableCell>
                      <TableCell>
                        {getCurrencyFormattedValue(
                          p.totalOtherFeesChargesEarned
                        )}
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </Box>
        ))}
        {evaluatedPolicy?.evaluatedPolicies?.length == 0
          ? "No policy rules apply to this student."
          : ""}
      </Box>
    </React.Fragment>
  );
};

export default RefundPolicy;
