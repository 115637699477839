import API from "../../apiWrapper";
export const GetStatusBySystemStatusCode = (systemStatusId: number) => {
  return API()
    .get("/SystemCatalog/StatusCodes/GetStatusBySystemStatusCode", {
      params: {
        systemStatusId
      }
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error.response;
      }
    );
};

export const AddDropReason = (reason: any) => {
  return API()
    .post("/SystemCatalog/DropReason/AddDropReason", reason)
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error.response;
      }
    );
};