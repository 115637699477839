import * as React from "react";
import { useRef } from "react";
import clsx from "clsx";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector } from "react-redux";
import { FilterParams } from "../../interfaces/common/FilterParams";
import { UndefinedChargeProgramApiAutoComplete } from "../../constants/SystemCatalog/UndefinedChargeProgramApiAutoComplete";

const useStyles = makeStyles((theme: any) =>
    createStyles({
        root: {},
        divWidth: {
            width: "95%",
        },
        fullWidth: { width: "100%", },
    })
);
export interface UndefinedChargeProgramProps extends FilterParams {
    campusId?: string;
    chargeDefinitionId: string;
    includeCreditProgramVersions?: boolean;
    onlyGEPrograms?: boolean;
}

const UndefinedChargeProgramsAutoComplete = (props: UndefinedChargeProgramProps) => {
    const classes = useStyles({});
    const inputRef: any = useRef(null);
    const userSelectedCampus = useSelector((state: any) =>
        state.userstate.getSelectedCampus(state.session.user.userId)
    );

    const PrgVerOnChange = (e: any) => {
        if (props.filterHandle) {
            props.filterHandle(e);
        }
    };

    return (
        <div className={props.params?.fullWidth ? clsx(classes.fullWidth) : clsx(classes.divWidth)}>
            <ApiAutoComplete
                disabled={props.disabled}
                config={UndefinedChargeProgramApiAutoComplete.config}
                loading={userSelectedCampus ? false : true}
                reqParams={
                    {
                        campusId: props.campusId ? props.campusId : userSelectedCampus,
                        chargeDefinitionId: props.chargeDefinitionId,
                        includeCreditProgramVersions: props?.includeCreditProgramVersions ?? false,
                        onlyGEPrograms: props?.onlyGEPrograms ?? false
                    }
                }
                classes={{
                    option: "text-black",
                }}
                label={props.label ? props.label : "Program Version"}
                placeholder={
                    props.valueFilter &&
                        props.valueFilter.values &&
                        props.valueFilter.values.length > 0
                        ? ""
                        : "Select a program version"
                }
                onChange={(e: any) => {
                    PrgVerOnChange(e);
                }}
                multiple={props.multiple ? props.multiple : false}
                valueFilter={props.valueFilter ? props.valueFilter : undefined}
                inputRef={props.inputRef ? props.inputRef : inputRef}
                error={props.error}
                helperText={props.helperText}
                inputName={props.name}
                showIncludeAllValue={props.showIncludeAllValue}
                chipSize={props.chipSize}
                maxTags={props.maxTags}
                includeAllValue={props.includeAllValue ? props.includeAllValue : false}
                defaultOptionText={props.defaultOptionText ? props.defaultOptionText : undefined}
            ></ApiAutoComplete>
        </div>
    );
};
export default UndefinedChargeProgramsAutoComplete;
