import { ChargePeriod } from "./ChargePeriod";

/* export interface ChargeDefinitionVersion {
	chargeDefinitionVersionId: string | null | undefined;
	effectiveDate: string | null;
	description: string | null;
	percentageOfExcusedAbsences: number | null;
	periods: ChargePeriod[] | null;
} */
export interface ChargeDefinitionVersion {
	chargeDefinitionVersionId: string | null;
	chargeDefinitionId: string | null;
	effectiveDate: Date | null;	
	description: string | null;
	excAbsencesPercent: number | null;
	totalChargeAmount: number | null;
	periods: Array<ChargeDefinitionPeriods>;
	asOfDateType: string ;
}

export interface ChargeDefinitionPeriods {
	incrementId: string;
	chargeDefinitionVersionId: string | null;
	chargeAmount: number;	
	transCodeId: string | null;
	transactionCode: string | null;
	actualHours: number | null;
	scheduledHours: number | null;
	hours: number;
  	options:any;
    isEditModeOn: boolean;
}

export class ChargeDefinitionVersionDef implements  ChargeDefinitionVersion {
	chargeDefinitionVersionId = null;
	chargeDefinitionId: string = "";
	effectiveDate = null;	
	description = null;
	excAbsencesPercent: number = 0;
	totalChargeAmount: number = 0;
	periods: Array<ChargeDefinitionPeriods> = [];
	asOfDateType: string = "StartDate";
}

export class ChargeDefinitionPeriodsDef implements ChargeDefinitionPeriods{
	incrementId: string = "";
	chargeDefinitionVersionId: string = "";
	chargeAmount: number = 0;	
	transCodeId: string | null = "";
	transactionCode = "";
	actualHours: number | null = null;
	scheduledHours: number | null = null;
	hours: number = 0;
	options:any = undefined;
    isEditModeOn: boolean = false;
}