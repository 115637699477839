import {
  CustomHeadCell,
  Order,
} from "../../../interfaces/common/Table/CustomHeadCell";
import CustomTableHeader from "../../../components/Tables/CustomTableHeader";
import React from "react";
import {
  TableBody,
  makeStyles,
  createStyles,
  TableRow,
  TableCell,
  TableFooter,
} from "@material-ui/core";
import * as TableFunctions from "../../../utils/tableFunctions";
import { ChargeDefinitionPeriods } from "../../../interfaces/setup/chargeDefinition/ChargeDefinitionVersion";
import { IIncrementDetails } from "../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/CharginMethod/IIncrementDetails";
import IncrementDetailsGridRow from "./IncrementDetailsGridRow";

type IncrementDetailsGridProps = {
  details: ChargeDefinitionPeriods[];
  isEditable: boolean;
  setModel: any;
  usesActualHours: boolean;
  readonly?: boolean;
};
const useStyles = makeStyles((theme: any) =>
  createStyles({
    tableWrapper: {
      width: "100%",
      height: "calc(100%)",
      overflowY: "auto",
      paddingBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    mainBody: {
      height: "100%",
    },
  })
);
const IncrementDetailsGrid = (props: IncrementDetailsGridProps) => {
  const classes = useStyles({});
  const headerCells: CustomHeadCell<ChargeDefinitionPeriods>[] = [
    {
      id: "actualHours",
      numeric: false,
      disablePadding: false,
      label: props.usesActualHours ? "Actual Hours" : "Scheduled Hours",
      widthType: "M",
    },
    {
      id: "chargeAmount",
      numeric: false,
      disablePadding: false,
      label: "Charge Amount",
      widthType: "M",
    },
    {
      id: "transCodeId",
      numeric: false,
      disablePadding: false,
      label: "Transaction Code",
      widthType: props.isEditable ? "Remainder" : "M",
    },
    {
      id: "options",
      numeric: false,
      disablePadding: false,
      label: "",
      widthType: "Option",
      isHidden: props.isEditable ? undefined : true,
    },
  ];

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] =
    React.useState<keyof ChargeDefinitionPeriods>("actualHours");

  const updateRow = (property: string, rowId: string, newValue: any) => {
    let arrayToUpdate: ChargeDefinitionPeriods[] = [...props.details];
    let modelToUpdate: any;
    modelToUpdate = arrayToUpdate.find((prop) => prop.incrementId === rowId);
    if (modelToUpdate !== undefined) {
      modelToUpdate[property as keyof ChargeDefinitionPeriods] = newValue;
    }
    props.setModel([...arrayToUpdate]);
  };

  const deleteRow = (rowId: string) => {
    let arrayToUpdate: ChargeDefinitionPeriods[] = [...props.details];
    let arrayUpdated = arrayToUpdate.filter((d) => d.incrementId !== rowId);
    props.setModel([...arrayUpdated]);
  };

  return (
    <div className={classes.tableWrapper}>
      <CustomTableHeader<ChargeDefinitionPeriods>
        cells={headerCells}
        defaultOrder={order}
        defaultOrderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        key={"tex"}
      />
      <TableBody className={classes.mainBody}>
        {TableFunctions.stableSort(
          props.details,
          TableFunctions.getSorting(order, orderBy) as any
        ).map((row: ChargeDefinitionPeriods) => {
          return (
            <IncrementDetailsGridRow
              row={row}
              updateRow={updateRow}
              usesActualHours={props.usesActualHours}
              removeRow={deleteRow}
              key={"increment_" + row.incrementId}
              readonly={props.readonly}
            />
          );
        })}
      </TableBody>
      <TableFooter>
        <TableRow key={"footer"}>
          <TableCell>{"Total"}</TableCell>
          <TableCell>
            {props.details && props.details.length > 0
              ? props.details
                  .map((d: ChargeDefinitionPeriods) =>
                    Number(d.chargeAmount ?? 0)
                  )
                  .reduce((a: number, b: number) => a + b, 0)
                  .toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
              : "$ 0.00"}
          </TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableFooter>
    </div>
  );
};

export default IncrementDetailsGrid;
