import API from "../../apiWrapper";

import {
  IStudentTermination,
  IR2T4Input,
  ITerminationResult,
  IFutureCharges,
  ITerminationReportProps,
  IR2T4Result,
} from "../../../interfaces/student/termination/IStudentTermination";
import { ReportOutput } from "../../../enums/ReportOutput";

export const GetRefundDetailsExist = (terminationId: string) => {
  return API()
    .get("/StudentAccounts/InstitutionalRefund/RefundDetailsExist", {
      params: {
        terminationId: terminationId,
      },
    })
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const GetR2T4CompletedStatus = (terminationId: string) => {
  return API()
    .get("/AcademicRecords/R2T4Input/GetR2T4CompletedStatus", {
      params: {
        terminationId: terminationId,
      },
    })
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const GetCalculationPeriodType = () => {
  return API()
    .get("/SystemCatalog/CalculationPeriodType/Get")
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const GetStudentAwardsByEnrollmentId = (
  enrollmentId: string,
  lastAttendedDate: Date
) => {
  return API()
    .get("/AcademicRecords/ProgramVersions/GetStudentAwardsByEnrollmentId", {
      params: {
        enrollmentId: enrollmentId,
        lastAttendedDate: lastAttendedDate,
      },
    })
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const GetIsResultsExists = (terminationId: string) => {
  return API()
    .get("/AcademicRecords/R2T4Result/IsResultsExists", {
      params: {
        terminationId: terminationId,
      },
    })
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const GetR2T4Result = (input: IR2T4Input) => {
  return API()
    .post("/AcademicRecords/R2T4Result/GetR2T4Result", input)
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const GetIsAnyTitleIVAwardExists = (
  enrollmentId: string,
  dateWithdrawalDetermined: Date
) => {
  return API()
    .get("/AcademicRecords/ProgramVersions/IsAnyTitleIVAwardExists", {
      params: {
        enrollmentId: enrollmentId,
        dateWithdrawalDetermined: dateWithdrawalDetermined,
      },
    })
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const GetStudentTermination = (enrollmentId: string) => {
  return API()
    .get("/AcademicRecords/StudentTermination/Get", {
      params: {
        enrollmentId: enrollmentId,
      },
    })
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const PostStudentTermination = (termination: IStudentTermination) => {
  return API()
    .post("/AcademicRecords/StudentTermination/Post", termination)
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const PutStudentTermination = (termination: IStudentTermination) => {
  return API()
    .put("/AcademicRecords/StudentTermination/Put", termination)
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const PostR2T4Input = (model: IR2T4Input) => {
  return API()
    .post("/AcademicRecords/R2T4Input/Post", model)
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const PutR2T4Input = (model: IR2T4Input) => {
  return API()
    .put("/AcademicRecords/R2T4Input/Put", model)
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const PostInputOverride = (model: IR2T4Input) => {
  return API()
    .post("/AcademicRecords/R2T4Input/PostOverride", model)
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const PutInputOverride = (model: IR2T4Input) => {
  return API()
    .put("/AcademicRecords/R2T4Input/PutOverride", model)
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const DeleteOverride = (terminationId: string) => {
  return API()
    .delete("/AcademicRecords/R2T4Input/DeleteOverride", {
      params: {
        terminationId: terminationId,
      },
    })
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const PostR2T4Result = (model: ITerminationResult) => {
  return API()
    .post("AcademicRecords/R2T4Result/Post", model)
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const PutR2T4Result = (model: ITerminationResult) => {
  return API()
    .put("/AcademicRecords/R2T4Result/Put", model)
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const PostResultOverride = (model: ITerminationResult) => {
  return API()
    .post("/AcademicRecords/R2T4Result/PostOverrideR2T4Result", model)
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const PutResultOverride = (model: ITerminationResult) => {
  return API()
    .put("/AcademicRecords/R2T4Result/PutOverrideResults", model)
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const DeleteResultOverride = (terminationId: string) => {
  return API()
    .delete("/AcademicRecords/R2T4Result/DeleteOverride", {
      params: {
        terminationId: terminationId,
      },
    })
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const GetEnrollmentsByStudentId = (
  studentId: string,
  isActiveEnrollments: boolean,
  isDroppedEnrollments: boolean
) => {
  return API()
    .post("/AcademicRecords/Enrollment/GetEnrollmentsByStudentId", {
      studentId: studentId,
      isActiveEnrollments: isActiveEnrollments,
      isDroppedEnrollments: isDroppedEnrollments,
    })
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const SaveRefundDetails = (terminationId: string) => {
  return API()
    .post("/StudentAccounts/InstitutionalRefund/SaveRefundDetails", terminationId)
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const GetEnrollmentDetail = (
  studentId: string,
  enrollmentId: string
) => {
  return API()
    .get("/AcademicRecords/Enrollment/GetEnrollmentDetail", {
      params: {
        studentId: studentId,
        enrollmentId: enrollmentId,
      },
    })
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const deleteTerminationById = (terminationId: string) => {
  return API()
    .delete("/AcademicRecords/StudentTermination/Delete", {
      params: {
        terminationId: terminationId,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error.response;
      }
    );
};

export const GetCalculateR2T4Step1Input = (
  enrollmentId: string,
  withdrawalDate: Date
) => {
  return API()
    .get("/AcademicRecords/R2T4Input/CalculateR2T4Step1Input", {
      params: {
        enrollmentId: enrollmentId,
        withdrawalDate: withdrawalDate,
      },
    })
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const GetCalculateR2T4Input = (
  terminationId: string,
  takeOverriddenIfExists: boolean
) => {
  return API()
    .get("/AcademicRecords/R2T4Input/Get", {
      params: {
        terminationId: terminationId,
        takeOverridenIfExists: takeOverriddenIfExists,
      },
    })
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const GetIsR2T4InputExists = (terminationId: string) => {
  return API()
    .get("/AcademicRecords/R2T4Input/IsR2T4InputExists", {
      params: {
        terminationId: terminationId,
      },
    })
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const GetHoursScheduledToComplete = (enrollmentId: string) => {
  return API()
    .get("/AcademicRecords/ProgramVersions/GetHoursScheduledToComplete", {
      params: {
        enrollmentId: enrollmentId,
      },
    })
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const GetIsClockHour = (studentEnrollmentId: string) => {
  return API()
    .get("/SystemCatalog/AcademicCalendar/IsClockHour", {
      params: {
        studentEnrollmentId: studentEnrollmentId,
      },
    })
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const GetIsUserAllowedToOverrideR2T4InputOrResultsInfo = () => {
  return API()
    .get(
      "/AcademicRecords/StudentTermination/IsUserAllowedToOverrideR2T4InputOrResultsInfo"
    )
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const GetProgramVersionDetailByEnrollmentId = (enrollmentId: string) => {
  return API()
    .get(
      "/AcademicRecords/ProgramVersions/GetProgramVersionDetailByEnrollmentId",
      {
        params: {
          enrollmentId: enrollmentId,
        },
      }
    )
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const GetTerminationResult = (terminationId: string) => {
  return API()
    .get("/AcademicRecords/R2T4Result/Get", {
      params: {
        terminationId: terminationId,
      },
    })
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const GetStudentLedger = (enrollmentId: string) => {
  return API()
    .get("/StudentAccounts/InstitutionalRefund/GetStudentLedger", {
      params: {
        enrollmentId: enrollmentId,
      },
    })
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const GetTerminationDetails = (terminationId: string) => {
  return API()
    .get("/AcademicRecords/ApproveTermination/GetTerminationDetails", {
      params: {
        terminationId: terminationId,
      },
    })
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const GetFutureCharges = (terminationId: string) => {
  return API()
    .get("/AcademicRecords/FutureCharges", {
      params: {
        terminationId: terminationId,
      },
    })
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const PostFutureCharges = (model: IFutureCharges) => {
  return API()
    .post("/AcademicRecords/FutureCharges", model)
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const UpdateFutureCharges = (model: IFutureCharges) => {
  return API()
    .put("/AcademicRecords/FutureCharges", model)
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const DeleteFutureCharges = (futureChargesId: string) => {
  return API()
    .delete("/AcademicRecords/FutureCharges", {
      params: {
        futureChargesId: futureChargesId,
      },
    })
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const IsAnyChargePosted = (terminationId: string) => {
  return API()
    .get("StudentAccounts/InstitutionalRefund/IsAnyChargePosted", {
      params: {
        terminationId: terminationId,
      },
    })
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const EvaluateRefundPolicies = (terminationId: string) => {
  return API()
    .get("/StudentAccounts/InstitutionalRefund/EvaluateRefundPolicies", {
      params: {
        terminationId: terminationId,
      },
    })
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const IsAllPoliciesAreByProgramVersion = (terminationId: string) => {
  return API()
    .get("/StudentAccounts/InstitutionalRefund/IsAllPoliciesAreByProgramVersion", {
      params: {
        terminationId: terminationId,
      },
    })
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const ProcessRefund = (terminationId: string) => {
  return API()
    .post("/StudentAccounts/InstitutionalRefund/ProcessRefund", terminationId)
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const PutEnrollmentDetails = (model: any) => {
  return API()
    .put("/AcademicRecords/ApproveTermination/PutEnrollmentDetails", model)
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const PostStudentStatusChanges = (model: any) => {
  return API()
    .post("/AcademicRecords/ApproveTermination/PostStudentStatusChanges", model)
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const GetPaymentsPeriodOptions = (
  stuEnrollId: string,
  periodType: string
) => {
  return API()
    .get(
      "/AcademicRecords/ProgramVersions/GetPaymentsPeriodOptionsByPeriodType",
      {
        params: {
          stuEnrollId: stuEnrollId,
          periodType: periodType,
        },
      }
    )
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const generateStudentSummaryReport = (
  reportPath: string,
  reportName: string,
  props: ITerminationReportProps  
) => {
  return API()
    .post(
      "/AcademicRecords/ApproveTermination/GenerateStudentSummaryReport",
      {
        reportDataSourceType: 1,
        reportLocation: "StudentTermination/" + reportPath,
        parameters: props,
        reportOutput: 1,
      },
      {
        responseType: "blob",
      }
    )
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return null;
      }
    );
};

export const generateRefundReport = (
  reportPath: string,
  reportName: string,
  props: ITerminationReportProps  
) => {
  return API()
    .post(
      "/StudentAccounts/InstitutionalRefund/GenerateRefundReport",
      {
        reportDataSourceType: 1,
        reportLocation: "StudentTermination/" + reportPath,
        parameters: props,
        reportOutput: 1,
      },
      {
        responseType: "blob",
      }
    )
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return null;
      }
    );
};
