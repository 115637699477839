import React, { useState, useEffect } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  Switch,
  RadioGroup,
  Radio,
  Typography,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { FormContext, useForm, FieldError } from "react-hook-form";
import {
  IStudentTermination,
  IStudentEnrollments,
  IR2T4Step1Input,
  IR2T4Input,
  IProgramVersionDetail,
  IScheduleHoursToComplete,
  ITerminationResult,
  IStudentLedger,
} from "../../../interfaces/student/termination/IStudentTermination";
import * as terminationApi from "../../../api/student/termination/studentTerminationApi";
import TerminationEnrollmentStatusAutoComplete from "../../../components/AutoComplete/TerminationEnrollmentStatusAutoComplete";
import DropReasonAutoComplete from "../../../components/AutoComplete/DropReasonAutoComplete";
import { StudentProfile } from "../../../interfaces/student/profile/StudentProfile";
import { EmptyGuid } from "../../../utils/constants";
import MuiAccordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import CustomCardTitle from "../../../interfaces/common/card/CustomCardTitle";
import { withStyles } from "@material-ui/core/styles";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import InfoIcon from "@material-ui/icons/Info";
import IconButton from "@material-ui/core/IconButton";
import CurrencyInput from "../../../components/_Layout/Inputs/CurrencyInput";
import InputField from "../../../components/_Layout/Inputs/InputField";
import { InputType } from "../../../constants/uiConstants/inputConstants";
import AddFutureCharges from "./AddFutureCharges";
import RefundPolicy from "./RefundPolicy";

const Accordion = withStyles({
  root: {
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      minHeight: "100%",
      paddingTop: theme.spacing(3),
      overflowX: "hidden",
      overflowY: "auto",
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
    startDateField: {
      width: "100%",
    },
  })
);
interface ITerminationProps {
  student: StudentProfile;
  enrollment: IStudentEnrollments | undefined;
  enrollmentId: string;
  terminationId: string;
  campusId: string;
  setConfirmationDialog: (params: any) => void;
  setSnackBar: (params: any) => void;
  setTab: (params: any) => void;
  deleteTermination: (params: any) => void;
  showLoading: any;
  hideLoading: any;
}

const InstitutionalRefundTab = (props: ITerminationProps) => {
  const { campusId, enrollmentId, terminationId, student, enrollment } = props;
  const classes = useStyles({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [titleIVGrants, setTitleIVGrants] = React.useState<IR2T4Step1Input>();
  const [studentLedger, setStudentLedger] = React.useState<IStudentLedger[]>(
    []
  );
  const [programVersionDetail, setProgramVersionDetail] =
    React.useState<IProgramVersionDetail>();
  const [scheduleHoursToComplete, setScheduleHoursToComplete] =
    React.useState<IScheduleHoursToComplete>();
  const [userAllowedToOverrideR2T4Input, setUserAllowedToOverrideR2T4Input] =
    React.useState<boolean>(false);
  const [override, setOverride] = React.useState<boolean>(false);
  const [pageKey, setPageKey] = React.useState<number>(1);
  const data = useForm<any>({ mode: "onBlur" });

  useEffect(() => {
    SaveRefundDetails(props.terminationId);
    GetUserAllowedToOverrideR2T4Input();
  }, [terminationId]);

  useEffect(() => {
    GetStudentLedger(enrollmentId);
  }, [enrollmentId]);

  const GetStudentLedger = (enrollmentId: string) => {
    terminationApi.GetStudentLedger(enrollmentId).then(
      (response: any) => {
        if (response) {
          setStudentLedger(response);
        }
      },
      (exception: any) => {}
    );
  };
  const GetUserAllowedToOverrideR2T4Input = () => {
    terminationApi.GetIsUserAllowedToOverrideR2T4InputOrResultsInfo().then(
      (response: any) => {
        setUserAllowedToOverrideR2T4Input(response);
      },
      (exception: any) => {}
    );
  };

  const SaveRefundDetails = (terminationId: string) => {
    terminationApi.SaveRefundDetails(terminationId).then(
      (response: any) => {
        if (response) {          
          //alert(JSON.stringify(response));
        }
      },
      (exception: any) => {}
    );
  };

  const GetEvaluateRefundPolicies = () => {
    setPageKey(pageKey + 1)
  };

  const onSubmit = () => {};
  const onApprove = () => {};
  
  const onCancel = async (confirm?: boolean) => {
    props.setConfirmationDialog({
      open: true,
      message:
        "Are you sure you want to cancel the student termination process? All current data entered, including the previously saved data, will be lost",
      onOk: () => deleteTermination(),
      onCancel: () => setLoading(false),
    });
  };

  const deleteTermination = () => {
    if (terminationId != EmptyGuid) {
      props.deleteTermination(terminationId);
    }
  };

  return (
    <div className={classes.root}>
      <Grid container direction="row" spacing={1}>
        <Grid item md={8} sm={8} xs={12}>
          <Grid container direction="row" spacing={1}>
            <Grid item md={4} sm={4} xs={12}>
              <b>Student: </b> ({student?.studentNumber}) {student?.studentName}
            </Grid>
            <Grid item md={4} sm={4} xs={12}>
              <b>SSN: </b>
              {student?.ssn}
            </Grid>
            <Grid item md={4} sm={4} xs={12}>
              <b>Enrollment: </b>
              {enrollment?.programVersionDescription}
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ArrowDownward />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>Student Ledger</Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <Box>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Transaction Date</TableCell>
                        <TableCell>Transaction code</TableCell>
                        <TableCell>Transaction description</TableCell>
                        <TableCell>Payment period/Term/Academic year</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Balance</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {studentLedger?.map((row: IStudentLedger, index: any) => (
                        <TableRow>
                          <TableCell>{row.transactionDate}</TableCell>
                          <TableCell>{row.transactionCode}</TableCell>
                          <TableCell>{row.transactionDescription}</TableCell>
                          <TableCell>{row.period}</TableCell>
                          <TableCell>{row.amount}</TableCell>
                          <TableCell>{row.balance}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <AddFutureCharges
                  terminationId={terminationId}
                  setSnackBar={props.setSnackBar}
                  setConfirmationDialog={props.setConfirmationDialog}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ArrowDownward />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>Refund Policy Evaluation</Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <Grid item md={12} sm={12} xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    GetEvaluateRefundPolicies();
                  }}
                  style={{ marginRight: "8px" }}
                >
                  Re-evaluate Policies
                </Button>
              </Grid>
              <RefundPolicy
                terminationId={terminationId}
                page="institutional"
                pageKey={pageKey}
                showLoading={props.showLoading}
                hideLoading={props.hideLoading}
                setConfirmationDialog={props.setConfirmationDialog}
                showFutureChargesMessage={true}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid spacing={1}>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          mt={2}
          mb={2}
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={() => props.setTab(2)}
            style={{ marginRight: "8px" }}
          >
            &lt; Back
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => props.setTab(4)}
            style={{ marginRight: "8px" }}
          >
            Next &gt;
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onSubmit()}
            style={{ marginRight: "8px" }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onCancel()}
            style={{ marginRight: "8px" }}
          >
            Cancel
          </Button>
          <Button
              variant="contained"
              color="secondary"
              onClick={() => onApprove()}
              style={{
                marginRight: "8px",
                backgroundColor: "green",
                color: "white",
              }}
            >
              Approve Termination
            </Button>
        </Box>
      </Grid>
      </Grid>
    </div>
  );
};

export default InstitutionalRefundTab;
